import OSS from 'ali-oss'

// 参数过滤函数(删除对象中值为null的属性)
export const removeNull = obj => {
    for (let key in obj) {
        if (obj[key] === null) {
            delete obj[key]
        }
        if (toType(obj[key]) === 'string') {
            obj[key] = obj[key].trim()
        } else if (toType(obj[key]) === 'object') {
            obj[key] = removeNull(obj[key])
        } else if (toType(obj[key]) === 'array') {
            obj[key] = removeNull(obj[key])
        }
    }
    return obj
}

// 自定义判断元素类型
export const toType = obj => {
    return ({}).toString.call(obj).match(/\s([a-zA-Z]+)/)[1].toLowerCase()
}

/*
** 获取随机串，参数为1位固定长度，2位为长度范围
** min-任意长度最小位[固定位数] max-任意长度最大位
*/
export const getRandomStr = (min, max) => {
    let str = ''
    let range = min
    let arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
    if (typeof max === 'number') {
        range = Math.round(Math.random() * (max - min)) + min; // Math.round()向上取整
    }
    for(let i = 0; i < range; i++) { // 随机从arr的0-arr.length之前获取一个字符
        const pos = Math.round(Math.random() * (arr.length - 1))
        str += arr[pos]
    }
    return str
}

export const getYMD = (type) => {
    let date = new Date()
    const Y = date.getFullYear()
    let M = date.getMonth() + 1
    M = M < 10 ? `0${M}` : M
    const D = date.getDate()
    switch (type) {
        case 'YYYY-MM-DD':
            return `${Y}-${M}-${D}`
            break
        case 'YYYYMMDD':
            return `${Y}${M}${D}`
            break
        default:
            return `${Y}/${M}/${D}`
            break
    }
}

// 对象转数组
export const objToArray = (obj) => {
    if (obj !== Object(obj))
        throw new TypeError('Object.values called on a non-object')
    let val=[]
    for (let key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            val.push(obj[key])
        }
    }
    return val
}

// export const readFileAsBuffer = (file) => {
//     const reader = new FileReader()
//     return new Promise((resolve, reject) => {
//         reader.readAsDataURL(file)
//         reader.onload = (e) => {
//             const base64File = reader.result.replace(/^data:\w+\/\w+;base64,/, '')
//             resolve(new OSS.Buffer(base64File, 'base64'))
//         }
//     })
// }

export const closest = (el, selector) => {
  const matchesSelector = el.matches || el.webkitMatchesSelector || el.mozMatchesSelector || el.msMatchesSelector
  while (el) {
    if (matchesSelector.call(el, selector)) {
      return el
    }
    el = el.parentElement
  }
  return null
}

export const onWrapTouchStart = (e) => {
    if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
        return
    }
    const pNode = closest(e.target, '.am-modal-content')
    if (!pNode) {
        e.preventDefault()
    }
}

// export const readFileAsBuffer = (file) => {
//     const reader = new FileReader()
//     return new Promise((resolve, reject) => {
//         reader.readAsDataURL(file)
//         reader.onload = (e) => {
//             const base64File = reader.result.replace(/^data:\w+\/\w+;base64,/, '')
//             resolve(new OSS.Buffer(base64File, 'base64'))
//         }
//     })
// }
// // 将文件名分割成[名字, 后缀]
// export const getFileNameAndSuffix = (filename) => {
//     const pos = filename.lastIndexOf('.')
//     let name = ''
//     let suffix = ''
//     if (pos !== -1) {
//         name = filename.substring(0, pos)
//         suffix = filename.substring(pos)
//     }
//     return [name, suffix]
// }
//
// // 现在文件（这里的文件指除图片外的文件，file包括url与name属性）
// export const downloadFile = (file) => {
//     getBlob(file.url, blob => {
//         saveAs(blob, file.name);
//     })
// }
//
// export const getBlob = (url, cb) => {
//     var xhr = new XMLHttpRequest()
//     xhr.open('GET', url, true)
//     xhr.responseType = 'blob'
//     xhr.onload = function() {
//         if (xhr.status === 200) {
//             cb(xhr.response)
//         }
//     }
//     xhr.send()
// }
// export const saveAs = (blob, filename) => {
//     if (window.navigator.msSaveOrOpenBlob) {
//         navigator.msSaveBlob(blob, filename)
//     } else {
//         let link = document.createElement('a')
//         let body = document.querySelector('body')
//         link.href = window.URL.createObjectURL(blob)
//         link.download = filename
//         // fix Firefox
//         link.style.display = 'none'
//         body.appendChild(link)
//         link.click()
//         body.removeChild(link)
//         window.URL.revokeObjectURL(link.href)
//     }
// }
