import * as actionTypes from '@/store/actionTypes/ossService'

// 获取oss上传的临时凭证
export const getStsToken = (params, callback) => ({ type: actionTypes.GET_STS_TOKEN, params, callback })
export const getStsTokenSuccess = (data) => ({ type: actionTypes.GET_STS_TOKEN_SUCCESS, data })
export const getStsTokenFailed = () => ({ type: actionTypes.GET_STS_TOKEN_FAILED })

// 获取oss上传的临时凭证
export const getStsUrl = (params, callback) => ({ type: actionTypes.GET_STS_URL, params, callback })
export const getStsUrlSuccess = (data) => ({ type: actionTypes.GET_STS_URL_SUCCESS, data })
export const getStsUrlFailed = () => ({ type: actionTypes.GET_STS_URL_FAILED })

// 获取文件地址列表
export const getUrlList = (params, callback) => ({ type: actionTypes.GET_URL_LIST, params, callback })
export const getUrlListSuccess = (data) => ({ type: actionTypes.GET_URL_LIST_SUCCESS, data })
export const getUrlListFailed = () => ({ type: actionTypes.GET_URL_LIST_FAILED })
