export const baseUrls = {
    dev: 'http://localhost:3000',
    test: 'http://testorder.nano.melot.top',
    prod: 'http://order.nano.melot.top'
}

export const baseApis = {
    dev: 'http://localhost:9000',
    test: 'http://testorder.nano.melot.top',
    prod: 'http://order.nano.melot.top'
}

export const corpIds = {
    dev: 'dingc84b94ed81bb51b2acaaa37764f94726', // 'dinge463d34ae59a937635c2f4657eb6378f',
    test: 'dingc84b94ed81bb51b2acaaa37764f94726', // 'dinge463d34ae59a937635c2f4657eb6378f',
    prod: 'dingbca31c04f2bf1db935c2f4657eb6378f'
}

export const appIds = { // AgentId
    dev: '1044542249', //'329462744',
    test: '1044576237', // '338173942',
    prod: '345836803'
}

export const appKeys = {
    dev: 'dingikeg22znwti1vvbo', // 'dingxkrqxbrvz9xllyw4',
    test: 'dingvxhdngujuiyp74os', // 'dingscbhsv3n4k4vjuwg',
    prod: 'dinghy5td4coofemfpll'
}
