import { baseUrls, baseApis, appIds, appKeys, corpIds } from '@/config'

export const getBaseUrl = () => {
    let baseUrl = ''
    switch (process.env.NODE_ENV) {
        case 'development':
            baseUrl = baseUrls.dev
            break
        case 'production':
            switch (process.env.REACT_APP_ENV) {
                case 'test':
                    baseUrl = baseUrls.test
                    break
                default:
                    baseUrl = baseUrls.prod
                    break
            }
            break
        default:
            break;
    }
    return baseUrl
}

export const getBaseApi = () => {
    let baseApi= ''
    switch (process.env.NODE_ENV) {
        case 'development':
            baseApi = baseApis.dev
            break
        case 'production':
            switch (process.env.REACT_APP_ENV) {
                case 'test':
                    baseApi = baseApis.test
                    break
                default:
                    baseApi = baseApis.prod
                    break
            }
            break
        default:
            break;
    }
    return baseApi
}

export const getAppId = () => {
    let appId= ''
    switch (process.env.NODE_ENV) {
        case 'development':
            appId = appIds.dev
            break
        case 'production':
            switch (process.env.REACT_APP_ENV) {
                case 'test':
                    appId = appIds.test
                    break
                default:
                    appId = appIds.prod
                    break
            }
            break
        default:
            break;
    }
    return appId
}

export const getAppKey = () => {
    let appKey= ''
    switch (process.env.NODE_ENV) {
        case 'development':
            appKey = appKeys.dev
            break
        case 'production':
            switch (process.env.REACT_APP_ENV) {
                case 'test':
                    appKey = appKeys.test
                    break
                default:
                    appKey = appKeys.prod
                    break
            }
            break
        default:
            break;
    }
    return appKey
}

export const getCorpId = () => {
    let corpId= ''
    switch (process.env.NODE_ENV) {
        case 'development':
            corpId = corpIds.dev
            break
        case 'production':
            switch (process.env.REACT_APP_ENV) {
                case 'test':
                    corpId = corpIds.test
                    break
                default:
                    corpId = corpIds.prod
                    break
            }
            break
        default:
            break;
    }
    return corpId
}
