import { put, call, takeEvery } from 'redux-saga/effects'
import * as actionTypes from '@/store/actionTypes/ossService'
import * as actions from '@/store/actions/ossService'
import * as request from './request'

export const ossServiceSaga = [
    takeEvery(actionTypes.GET_STS_TOKEN, getStsToken), // 获取钉钉签名参数
    takeEvery(actionTypes.GET_STS_URL, getStsUrl), // 获取oss文件下载路径
    takeEvery(actionTypes.GET_URL_LIST, getUrlList), // 获取文件地址列表
]

export function* getUrlList(action) {
    try {
        const data = yield call(request.getUrlList, action)
        action.callback && action.callback(data)
        yield put(actions.getUrlListSuccess(data))
    } catch(error) {
        yield put(actions.getUrlListFailed())
    }
}

export function* getStsUrl(action) {
    try {
        const data = yield call(request.getStsUrl, action)
        action.callback && action.callback(data)
        yield put(actions.getStsUrlSuccess(data))
    } catch(error) {
        yield put(actions.getStsUrlFailed())
    }
}

export function* getStsToken(action) {
    try {
        const data = yield call(request.getStsToken, action)
        action.callback && action.callback(data)
        yield put(actions.getStsTokenSuccess(data))
    } catch(error) {
        yield put(actions.getStsTokenFailed())
    }
}
