import { put, call, takeEvery } from 'redux-saga/effects'
import * as actionTypes from '@/store/actionTypes/dbService'
import * as actions from '@/store/actions/dbService'
import * as request from './request'

export const dbServiceSaga = [
    takeEvery(actionTypes.GET_ALL_INSTANCE, getAllInstance), // 获取应用对应数据库
]

export function* getAllInstance(action) {
    try {
        const data = yield call(request.getAllInstance, action)
        action.callback && action.callback(data)
        yield put(actions.getAllInstanceSuccess(data))
    } catch(error) {
        yield put(actions.getAllInstanceFailed())
    }
}