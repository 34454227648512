import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createBrowserHistory } from 'history'
import * as accountServiceActions from '@/store/actions/accountService'
import * as workOrderServiceActions from '@/store/actions/workOrderService'
import * as dd from 'dingtalk-jsapi'
import { Button, List, TextareaItem, ActivityIndicator, Modal } from 'antd-mobile'
import { createForm } from 'rc-form'
import { getStorage, setStorage, removeStorage, clearStorage } from '@/util/storage'
import { Upload } from 'antd'
import './index.less'

const history = createBrowserHistory({
    forceRefresh: true
})

class SubmitActions extends Component {
    state = {
        canClickNextStepBtn: false,
        token: null,
        user_id: null,
        app_name: '',
        in_appid: null,
        operation_name: '',
        feature_name: '',
        cascadingMenuParams: {
            in_type: 'app_list', // 类型：app_list, operation_list, feature_list
        },
        platform: 'pc', // pc、android、ios
        activityIndicatorAnimating: false,
        modalVisible: true,
    }
    componentDidMount() {
        // clearStorage()
        const { title } = this.props
        if (dd.env.platform !== 'notInDingTalk') {
            dd.biz.navigation.setTitle({ title: title })
        }
        const { user_token, user_id } = getStorage()
        if (user_token && user_id) {
            this.setState({
                token: user_token,
                user_id: user_id,
            }, () => {
                this.getInitialData()
                // history.push(`/app/detail?apply_id=3748`)
            })
        }
    }
    render() {
        const { canClickNextStepBtn, app_name, operation_name, feature_name } = this.state
        return (
            <div className="page-container operation-page-container">
                <div className="page-view-operation">
                    <List className="app-operation-feature" size={['large']}>
                        <List.Item onClick={() => this.clickActionItem(0)} arrow="horizontal" extra={ <span className={ app_name ? 'active' : ''}>{app_name ? app_name : '请选择'}</span> }>业务产品</List.Item>
                        <List.Item disabled={!app_name} onClick={() => this.clickActionItem(1)} arrow="horizontal" extra={ <span className={ operation_name ? 'active' : ''}>{operation_name ? operation_name : '请选择'}</span> }>业务类型</List.Item>
                        <List.Item disabled={!(app_name && operation_name)} onClick={() => this.clickActionItem(2)} arrow="horizontal" extra={ <span className={ feature_name ? 'active' : ''}>{feature_name ? feature_name : '请选择'}</span> }>业务子类型</List.Item>
                    </List>
                    <div className="btns">
                        <div className="btns-container">
                            <Button onClick={() => this.clickNextBtn()} disabled={!canClickNextStepBtn} style={{ width: '100%', borderRight: 'initial', borderBottom: 'initial' }} className={`me-button action-button ${!canClickNextStepBtn ? 'disabled' : ''}`}>下一步</Button>
                        </div>
                    </div>
                </div>
                <ActivityIndicator animating={this.state.activityIndicatorAnimating} toast />
                {
                    <Modal
                        className="detail-message-modal"
                        visible={false}
                        closable={true}
                        maskClosable={true}
                        title="执行完成"
                        footer={[
                            {text: '取消'},
                            {text: '确认', onPress: () => { history.push('/app/detail?apply_id=3081') }}
                        ]}
                        onClose={this.closeModal}>
                            <div className='modal-content-container'>
                                <List className="me-list-group">
                                    <List.Item className='modal-message' key={1}>
                                        {`确认执行?`}
                                    </List.Item>
                                    <List.Item key={2} className="file-container">
                                        <List.Item.Brief>
                                            <TextareaItem
                                                rows={3}
                                                placeholder="请输入"
                                            ></TextareaItem>
                                        </List.Item.Brief>
                                    </List.Item>
                                    <List.Item key={1} className="file-container">
                                        <List.Item.Brief>
                                            <Upload className='upload-container'>
                                                <Button className="file-plus">附件+</Button>
                                            </Upload>
                                        </List.Item.Brief>
                                    </List.Item>
                                </List>
                            </div>
                    </Modal>
                }
            </div>
        )
    }
    getInitialData = (callback) => {
        const { action_params, cascadingMenuParams } = getStorage()
        const { token, user_id } = this.state
        if (action_params) {
            const { app_name, app_name_code, operation_name, feature_name, wfid } = JSON.parse(action_params)
            this.setState({
                app_name,
                app_name_code,
                operation_name,
                feature_name,
                wfid,
                cascadingMenuParams: JSON.parse(cascadingMenuParams),
                canClickNextStepBtn: true
            })
        } else {
            this.setState({
                app_name: '',
                operation_name: '',
                feature_name: '',
                wfid: null,
                cascadingMenuParams: {
                    in_type: 'app_list'
                },
                canClickNextStepBtn: false
            })
        }
        if (!token || !user_id) {
            const { user_token, user_id } = getStorage()
            this.setState({
                token: user_token,
                user_id: user_id,
            }, () => {
                callback && callback()
            })
        } else {
            callback && callback()
        }
    }
    closeModal = () => {
        this.setState({ modalVisible: false })
    }
    getCascadingMenu = (cascadingMenuParams, callback) => {
        const { getCascadingMenu } = this.props.actions
        if (!this.state.token && !this.state.user_id) {
            this.getInitialData(() => {
                getCascadingMenu({
                    token: this.state.token,
                    user_id: this.state.user_id,
                    ...cascadingMenuParams, // in_app_id, in_app_operation
                }, res => {
                    this.hideIndicatorLoading()
                    if (res.status === 'success') {
                        callback && callback(res)
                    }
                })
            })
        } else {
            getCascadingMenu({
                token: this.state.token,
                user_id: this.state.user_id,
                ...cascadingMenuParams, // in_app_id, in_app_operation
            }, res => {
                this.hideIndicatorLoading()
                if (res.status === 'success') {
                    callback && callback(res)
                }
            })
        }
    }
    clickActionItem = (type) => {
        const _this = this
        const title_list = ['业务产品', '业务类型', '业务子类型']
        const { app_name, operation_name, feature_name } = this.state
        const { cascadingMenuParams } = this.state
        this.showIndicatorLoading()
        switch (type) {
            case 0: // 点击业务产品
                this.getCascadingMenu({
                    in_type: 'app_list'
                }, res => {
                    if (res.status === 'success') {
                        const cascadingMenu = res.data.app_list
                        // let name_list = this.getNameList(cascadingMenu)
                        let name_list = cascadingMenu
                        dd.device.notification.actionSheet({
                            title: `选择${title_list[type]}`,
                            cancelButton: '取消',
                            otherButtons: this.getNameList(cascadingMenu),
                            onSuccess : function(result) {
                                const { buttonIndex } = result
                                if (buttonIndex >= 0) {
                                    const in_appid = cascadingMenu[buttonIndex]['id']
                                    let state_params = {
                                        app_name: name_list[buttonIndex].name,
                                        app_name_code: name_list[buttonIndex].app_name,
                                        in_appid: in_appid,
                                        cascadingMenuParams: { in_type: 'operation_list', in_appid: in_appid }
                                    }
                                    if (in_appid !== _this.state.in_appid) {
                                        state_params.operation_name = ''
                                        state_params.feature_name = ''
                                        state_params.wfid = null
                                        state_params.canClickNextStepBtn = false
                                    }
                                    _this.setState(state_params)
                                }
                            },
                            onFail : function(err) {console.log(err)}
                        })
                    }
                })
                break
            case 1: // 点击业务类型
                if (app_name) {
                    this.getCascadingMenu({
                        in_type: 'operation_list',
                        in_appid: cascadingMenuParams.in_appid >= 0 ? cascadingMenuParams.in_appid : this.state.in_appid
                    }, res => {
                        if (res.status === 'success') {
                            const cascadingMenu = res.data.operation_list
                            let name_list = this.getNameList(cascadingMenu)//cascadingMenu.map(app => app.name)
                            // let name_list = cascadingMenu
                            dd.device.notification.actionSheet({
                                title: `选择${title_list[type]}`,
                                cancelButton: '取消',
                                otherButtons: name_list,
                                onSuccess : function(result) {
                                    const { buttonIndex } = result
                                    if (buttonIndex >= 0) {
                                        const in_operation_id = cascadingMenu[buttonIndex]['id']
                                        let state_params = {
                                            operation_name: name_list[buttonIndex],
                                            // app_name_code: name_list[buttonIndex].app_name,
                                            in_operation_id: in_operation_id,
                                            cascadingMenuParams: {
                                                in_type: 'feature_list',
                                                in_appid: cascadingMenuParams.in_appid >= 0 ? cascadingMenuParams.in_appid : _this.state.in_appid,
                                                in_operation_id: in_operation_id
                                            }
                                        }
                                        if (in_operation_id !== _this.state.in_operation_id) {
                                            state_params.feature_name = ''
                                            state_params.wfid = null
                                            state_params.canClickNextStepBtn = false
                                        }
                                        _this.setState(state_params)
                                    }
                                },
                                onFail : function(err) {console.log(err)}
                            })
                        }
                    })
                } else {
                    dd.device.notification.toast({
                        text: '请确认业务产品是否已选择！',
                        duration: 2
                    })
                    this.hideIndicatorLoading()
                }
                break
            case 2: // 点击业务子类型
                if (app_name && operation_name) {
                    this.getCascadingMenu({
                        in_type: 'feature_list',
                        in_appid: cascadingMenuParams.in_appid >= 0 ? cascadingMenuParams.in_appid : this.state.in_appid,
                        in_operation_id: cascadingMenuParams.in_operation_id >= 0 ? cascadingMenuParams.in_operation_id : this.state.in_operation_id
                    }, res => {
                        if (res.status === 'success') {
                            const cascadingMenu = res.data.feature_list
                            let name_list = this.getNameList(cascadingMenu)
                            // let name_list = cascadingMenu
                            dd.device.notification.actionSheet({
                                title: `选择${title_list[type]}`,
                                cancelButton: '取消',
                                otherButtons: name_list,
                                onSuccess : function(result) {
                                    const { buttonIndex } = result
                                    if (buttonIndex >= 0) {
                                        _this.setState({
                                            feature_name: name_list[buttonIndex],
                                            // app_name_code: name_list[buttonIndex].app_name,
                                            wfid: cascadingMenu[buttonIndex]['wfid'],
                                            canClickNextStepBtn: true,
                                            cascadingMenuParams: Object.assign(_this.state.cascadingMenuParams, {
                                                in_feature_id:  cascadingMenu[buttonIndex]['id']
                                            })
                                        })
                                    }
                                },
                                onFail : function(err) {console.log(err)}
                            })
                        }
                    })
                } else {
                    dd.device.notification.toast({
                        text: '请确认业务产品与类型是否已选择！',
                        duration: 2
                    })
                    this.hideIndicatorLoading()
                }
                break
            default:
                break
        }
    }
    getNameList = (cascadingMenu) => {
        return cascadingMenu.map(item => item.name)
    }
    clickNextBtn = () => {
        const { app_name, app_name_code, operation_name, feature_name, cascadingMenuParams, wfid, token, user_id } = this.state
        if (wfid >= 0) {
            const { checkWfidEnable } = this.props.actions
            this.showIndicatorLoading()
            checkWfidEnable({
                in_wfid: wfid,
                token,
                user_id,
            }, res => {
                this.hideIndicatorLoading()
                if (res.status === 'success') { // 有效的wfid
                    const action_params = {
                        app_name,
                        app_name_code,
                        operation_name,
                        feature_name,
                        wfid,
                        appid: cascadingMenuParams.in_appid,
                        operation_id: cascadingMenuParams.in_operation_id,
                        feature_id: cascadingMenuParams.in_feature_id,
                    }
                    setStorage('action_params', JSON.stringify(action_params))
                    setStorage('cascadingMenuParams', JSON.stringify(cascadingMenuParams))
                    history.push(`/app/content?app_name_code=${app_name_code}`)
                } else { // 无效的wfid
                    removeStorage('action_params')
                    removeStorage('cascadingMenuParams')
                    this.getInitialData()
                }
            })

        }
    }
    showIndicatorLoading = () => {
        this.setState({ activityIndicatorAnimating: true }, () => {
            setTimeout(() => {
                if (this.state.activityIndicatorAnimating) {
                    this.setState({activityIndicatorAnimating: false})
                }
            }, 6000)
        })
    }
    hideIndicatorLoading = () => {
        this.setState({activityIndicatorAnimating: false})
    }
}

const mapStateToProps = (state) => ({
    accountService: state.accountService,
    workOrderService: state.workOrderService,
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Object.assign({}, accountServiceActions, workOrderServiceActions), dispatch)
})
const SubmitActionsForm = createForm()(SubmitActions)
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubmitActionsForm)
