import * as dd from 'dingtalk-jsapi'
import { getAppId, getCorpId } from '@/util/config'

export const requestAuthCode = (callback) => { // jsApiSignatureParams
    if (dd.env.platform !== "notInDingTalk") { // 只有在钉钉内部环境中才执行
        // 进行钉钉登录操作
        dd.ready(() => {
            // dd.ready参数为回调函数，在环境准备就绪时触发，jsapi的调用需要保证在该回调函数触发后调用，否则无效。
            dd.runtime.permission.requestAuthCode({
                corpId: getCorpId(), // 'dinge463d34ae59a937635c2f4657eb6378f',
                onSuccess: result => {
                    const authCode = result.code // 免登授权码
                    callback && callback(authCode)
                },
                onFail: err => {
                    console.log('Login Error:', err)
                }
            })
        })
    } else {
        console.log('只有在钉钉内部环境中才执行！')
    }
}

// 设置前端鉴权配置 jsApiSignature：corpId, nonceStr, signature, timeStamp
export const setDDConfig = (jsApiSignature, url, callback) => {
    dd.config({
        url: url,
        agentId: getAppId(), // 必填，微应用ID
        ...jsApiSignature,
        jsApiList: [ // 必填，需要使用的jsapi列表，注意：不要带dd。
            'runtime.info',
            'device.notification.confirm',
            'device.notification.alert',
            'device.notification.prompt',
            'biz.ding.post',
            'biz.util.openLink',
            'biz.contact.choose',
            'biz.contact.complexPicker'
        ]
    })
    dd.error(err => {
        dd.device.notification.toast({
            text: err.errorMessage, //提示信息
            duration: 2, //显示持续时间，单位秒，默认按系统规范[android只有两种(<=2s >2s)]
        })
        console.log(err)
    })
    dd.ready(() => {
        callback && callback()
    })

}

// export const getJsApiSignature = (ticket, nonce, timeStamp, url) => {
//     let plainTex = 'jsapi_ticket=' + ticket + '&noncestr=' + nonce + '&timestamp=' + timeStamp + '&url=' + url
//     let signature = CryptoJS.SHA1(plainTex).toString()
//     return signature
// }
