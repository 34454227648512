export const GET_CASCADING_MENU = 'GET_CASCADING_MENU'
export const GET_CASCADING_MENU_SUCCESS = 'GET_CASCADING_MENU_SUCCESS'
export const GET_CASCADING_MENU_FAILED = 'GET_CASCADING_MENU_FAILED'

export const GET_COMPONENTS = 'GET_COMPONENTS'
export const GET_COMPONENTS_SUCCESS = 'GET_COMPONENTS_SUCCESS'
export const GET_COMPONENTS_FAILED = 'GET_COMPONENTS_FAILED'

export const GET_CC_LIST_FOR_CREATE = 'GET_CC_LIST_FOR_CREATE'
export const GET_CC_LIST_FOR_CREATE_SUCCESS = 'GET_CC_LIST_FOR_CREATE_SUCCESS'
export const GET_CC_LIST_FOR_CREATE_FAILED = 'GET_CC_LIST_FOR_CREATE_FAILED'

export const GET_APPROVERS_FOR_CREATE = 'GET_APPROVERS_FOR_CREATE'
export const GET_APPROVERS_FOR_CREATE_SUCCESS = 'GET_APPROVERS_FOR_CREATE_SUCCESS'
export const GET_APPROVERS_FOR_CREATE_FAILED = 'GET_APPROVERS_FOR_CREATE_FAILED'

export const GET_COMPONENTS_FOR_DETAIL = 'GET_COMPONENTS_FOR_DETAIL'
export const GET_COMPONENTS_FOR_DETAIL_SUCCESS = 'GET_COMPONENTS_FOR_DETAIL_SUCCESS'
export const GET_COMPONENTS_FOR_DETAIL_FAILED = 'GET_COMPONENTS_FOR_DETAIL_FAILED'

export const CREATE_ORDER = 'CREATE_ORDER'
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS'
export const CREATE_ORDER_FAILED = 'CREATE_ORDER_FAILED'

export const GET_APPROVERS_FOR_DETAIL = 'GET_APPROVERS_FOR_DETAIL'
export const GET_APPROVERS_FOR_DETAIL_SUCCESS = 'GET_APPROVERS_FOR_DETAIL_SUCCESS'
export const GET_APPROVERS_FOR_DETAIL_FAILED = 'GET_APPROVERS_FOR_DETAIL_FAILED'

export const GET_BUTTON_LIST = 'GET_BUTTON_LIST'
export const GET_BUTTON_LIST_SUCCESS = 'GET_BUTTON_LIST_SUCCESS'
export const GET_BUTTON_LIST_FAILED = 'GET_BUTTON_LIST_FAILED'

export const ORDER_URGE = 'ORDER_URGE'
export const ORDER_URGE_SUCCESS = 'ORDER_URGE_SUCCESS'
export const ORDER_URGE_FAILED = 'ORDER_URGE_FAILED'

export const ORDER_CANCEL = 'ORDER_CANCEL'
export const ORDER_CANCEL_SUCCESS = 'ORDER_CANCEL_SUCCESS'
export const ORDER_CANCEL_FAILED = 'ORDER_CANCEL_FAILED'

export const ORDER_APPROVE_DONE = 'ORDER_APPROVE_DONE'
export const ORDER_APPROVE_DONE_SUCCESS = 'ORDER_APPROVE_DONE_SUCCESS'
export const ORDER_APPROVE_DONE_FAILED = 'ORDER_APPROVE_DONE_FAILED'

export const ORDER_APPROVE_REJECT = 'ORDER_APPROVE_REJECT'
export const ORDER_APPROVE_REJECT_SUCCESS = 'ORDER_APPROVE_REJECT_SUCCESS'
export const ORDER_APPROVE_REJECT_FAILED = 'ORDER_APPROVE_REJECT_FAILED'

export const ORDER_EXECUTE = 'ORDER_EXECUTE'
export const ORDER_EXECUTE_SUCCESS = 'ORDER_EXECUTE_SUCCESS'
export const ORDER_EXECUTE_FAILED = 'ORDER_EXECUTE_FAILED'

export const ORDER_EXECUTE_REJECT = 'ORDER_EXECUTE_REJECT'
export const ORDER_EXECUTE_REJECT_SUCCESS = 'ORDER_EXECUTE_REJECT_SUCCESS'
export const ORDER_EXECUTE_REJECT_FAILED = 'ORDER_EXECUTE_REJECT_FAILED'

export const ORDER_EXECUTE_DONE = 'ORDER_EXECUTE_DONE'
export const ORDER_EXECUTE_DONE_SUCCESS = 'ORDER_EXECUTE_DONE_SUCCESS'
export const ORDER_EXECUTE_DONE_FAILED = 'ORDER_EXECUTE_DONE_FAILED'

export const ORDER_AUTO_EXECUTE = 'ORDER_AUTO_EXECUTE'
export const ORDER_AUTO_EXECUTE_SUCCESS = 'ORDER_AUTO_EXECUTE_SUCCESS'
export const ORDER_AUTO_EXECUTE_FAILED = 'ORDER_AUTO_EXECUTE_FAILED'

export const CHECK_WFID_ENABLE = 'CHECK_WFID_ENABLE'
export const CHECK_WFID_ENABLE_SUCCESS = 'CHECK_WFID_ENABLE_SUCCESS'
export const CHECK_WFID_ENABLE_FAILED = 'CHECK_WFID_ENABLE_FAILED'

export const CHECK_APPLY_ID_AUTHORIZED = 'CHECK_APPLY_ID_AUTHORIZED'
export const CHECK_APPLY_ID_AUTHORIZED_SUCCESS = 'CHECK_APPLY_ID_AUTHORIZED_SUCCESS'
export const CHECK_APPLY_ID_AUTHORIZED_FAILED = 'CHECK_APPLY_ID_AUTHORIZED_FAILED'

export const GET_NEXT_TASK_APPLY_ID = 'GET_NEXT_TASK_APPLY_ID'
export const GET_NEXT_TASK_APPLY_ID_SUCCESS = 'GET_NEXT_TASK_APPLY_ID_SUCCESS'
export const GET_NEXT_TASK_APPLY_ID_FAILED = 'GET_NEXT_TASK_APPLY_ID_FAILED'

export const SYNTAX_CHECK = 'SYNTAX_CHECK'
export const SYNTAX_CHECK_SUCCESS = 'SYNTAX_CHECK_SUCCESS'
export const SYNTAX_CHECK_FAILED = 'SYNTAX_CHECK_FAILED'
