import React from 'react'
import { RenderRoutes } from '@/routes/renderRoutes'

class MeLayout extends React.Component {
    render() {
        let { routes } = this.props
        return (
            <RenderRoutes routes={routes}></RenderRoutes>
        )
    }
}

export default MeLayout