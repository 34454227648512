import React from 'react'
import { Route, Redirect } from 'react-router-dom'

// 循环渲染当前路由数组中一维数组中的组件
export const RenderRoutes = ({routes}) => {
    return (routes.map((route, i) => <RenderSubRoutes key={route.path} {...route} />))
}

// 渲染当前组件（组件中的数组组件）
export const RenderSubRoutes = route => (
    <Route
        path={route.path}
        exact={route.exact}
        render={props => {
            return (
                route && (route.redirect ? (<Redirect to={route.redirect}></Redirect>) : (<route.component {...props} title={route.title} routes={route.routes} />))
            )
        }}
    />
)
