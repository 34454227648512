import { put, call, takeEvery } from 'redux-saga/effects'
import * as actionTypes from '@/store/actionTypes/dingtalkService'
import * as actions from '@/store/actions/dingtalkService'
import * as request from './request'

export const dingtalkServiceSaga = [
    takeEvery(actionTypes.GET_JS_API_SIGNATURE, getJsApiSignature), // 获取钉钉签名参数
    takeEvery(actionTypes.GET_JS_API_TICKET, getJsApiTicket), // 获取钉钉鉴权参数jsapi_ticket
]

export function* getJsApiTicket(action) {
    try {
        const data = yield call(request.getJsApiTicket, action)
        action.callback && action.callback(data)
        yield put(actions.getJsApiTicketSuccess(data))
    } catch(error) {
        yield put(actions.getJsApiTicketFailed())
    }
}

export function* getJsApiSignature(action) {
    try {
        const data = yield call(request.getJsApiSignature, action)
        action.callback && action.callback(data)
        yield put(actions.getJsApiSignatureSuccess(data))
    } catch(error) {
        yield put(actions.getJsApiSignatureFailed())
    }
}