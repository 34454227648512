import request from '@/config/http'

export const getCascadingMenu = action => request.post('/workOrderService/gal/get_cascading_menu', action.params) // 获取 app_id、operation、feature
export const getComponents = action => request.post('/workOrderService/gal/get_components_by_wfid', action.params) // 获取页面组件内容
export const getCCListForCreate = action => request.post('/workOrderService/gal/get_cc_list_by_wfid', action.params) // 获取抄送人列表（新建工单）
export const getApproversForCreate = action => request.post('/workOrderService/gal/get_work_flow_node_list', action.params) // 获取审批人列表（新建工单）
export const getComponentsForDetail = action => request.post('/workOrderService/gal/apply_get_page_components', action.params) // 获取工单详情组件内容（工单详情）
export const createOrder = action => request.post('/workOrderService/gal/apply_create', action.params) // 新建工单
export const getApproversForDetail = action => request.post('/workOrderService/gal/apply_get_approval_list', action.params) // 获取审批人列表（工单详情）
export const getButtonList = action => request.post('/workOrderService/gal/apply_get_user_menu_list', action.params) // 获取可操作按钮（工单详情）
export const orderUrge = action => request.post('/workOrderService/gal/apply_urge', action.params) // 催办
export const orderCancel = action => request.post('/workOrderService/gal/apply_cancel', action.params) // 撤销
export const orderApproveDone = action => request.post('/workOrderService/gal/apply_agree', action.params) // 审批通过
export const orderApproveReject = action => request.post('/workOrderService/gal/apply_reject', action.params) // 审批驳回
export const orderExecute = action => request.post('/workOrderService/gal/apply_execute', action.params) // 执行
export const orderExecuteReject = action => request.post('/workOrderService/gal/apply_execute_reject', action.params) // 执行驳回
export const orderExecuteDone = action => request.post('/workOrderService/gal/apply_execute_complete', action.params) // 执行完成
export const orderAutoExecute = action => request.post('/workOrderService/gal/apply_auto_execute', action.params) // 自动执行
export const checkWfidEnable = action => request.post('/workOrderService/gal/get_check_wfid', action.params) // 检测wfid是否有效
export const checkApplyIdAuthorized = action => request.post('/workOrderService/gal/apply_whether_visible', action.params) // 检测apply_id是否有权限
export const getNextTaskApplyId = action => request.post('/workOrderService/gal/get_next_task_apply_id', action.params) // 获取下一条待操作的工单
export const syntaxCheck = action => request.post('/workOrderService/gal/syntax_check', action.params) // 检测自动执行语句是否有效
