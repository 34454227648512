import { all } from 'redux-saga/effects'
import { accountServiceSaga } from './accountService'
import { workOrderServiceSaga } from './workOrderService'
import { dingtalkServiceSaga } from './dingtalkService'
import { ossServiceSaga } from './ossService'
import { dbServiceSaga } from './dbService'

function* rootSaga() {
    yield all([
        ...accountServiceSaga,
        ...workOrderServiceSaga,
        ...dingtalkServiceSaga,
        ...ossServiceSaga,
        ...dbServiceSaga,
    ])
}

export default rootSaga
