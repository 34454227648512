import { combineReducers } from 'redux'
import * as actionTypes from '@/store/actionTypes/accountService'

const login = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_SUCCESS:
            if (action.data.status === 'success') {
                return  action.data.data
            } else {
                return state
            }
        default:
            return state
    }
}

const getUserInfo = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.GET_USER_INFO_SUCCESS:
            if (action.data.status === 'success') {
                return action.data.data
            } else {
                return state
            }
        default:
            return state
    }
}

export default combineReducers({
    loginInfo: login, // 用户登录
    userInfo: getUserInfo, // 用户信息
})