import { combineReducers } from 'redux'
import * as actionTypes from '@/store/actionTypes/dbService'

const getAllInstance = (state = [], action) => {
    switch (action.type) {
        case actionTypes.GET_ALL_INSTANCE_SUCCESS:
            if (action.data.status === 'success') {
                return  action.data.message
            } else {
                return state
            }
        default:
            return state
    }
}

export default combineReducers({
    allInstance: getAllInstance // 获取应用对应数据库
})