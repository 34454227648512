import { combineReducers } from 'redux'
import accountService from './accountService'
import workOrderService from './workOrderService'
import dingtalkService from './dingtalkService'
import ossService from './ossService'
import dbService from './dbService'

export default combineReducers({
    accountService,
    workOrderService,
    dingtalkService,
    ossService,
    dbService
})
