import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import rootReducer from './reducers'
import rootSaga from './sagas'

const sagaMiddleware = createSagaMiddleware()
const middleWares = [sagaMiddleware]

export const configureStore = () => {
    const store = createStore(
        rootReducer,
        applyMiddleware(...middleWares)
    )
    sagaMiddleware.run(rootSaga)
    return store
}