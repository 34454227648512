import React from 'react'
import { Provider } from 'react-redux'
import { configureStore } from '@/store/configureStore'
import { BrowserRouter as Router } from 'react-router-dom'
import { RenderRoutes } from '@/routes/renderRoutes'
import { routes } from '@/routes'
import { hot } from 'react-hot-loader/root'
import './App.less'


const store = configureStore()

class App extends React.Component {
  render() {
    return (
        <Provider store={store}>
          <Router>
              <RenderRoutes routes={routes} />
          </Router>
        </Provider>
    )
  }
}

export default process.env.NODE_ENV === 'development' ? hot(App) : App
