let storage = {}
let isLocalStorageEnv = window.localStorage ? true : false

if (!isLocalStorageEnv) {
    console.log('浏览器不支持localStorage')
}

storage = isLocalStorageEnv ? window.localStorage : ''

export const getStorage = (key) => {
    let value = '';
    if (key) {
        value = storage.getItem(key) ? JSON.parse(storage.getItem(key)) : ''
    } else {
        value = window.localStorage
    }
    return value
}

export const setStorage = (key, value) => {
    if (key) {
        storage.setItem(key, value)
    } else {
        console.log('the key of setStorage is undefined')
    }
    // if (typeof value === 'object') {
    //     storage.setItem(key, JSON.stringify(value))
    // } else {
    //     storage.setItem(key, value)
    // }
}

export const removeStorage = (key) => {
    storage.removeItem(key)
}

export const clearStorage = () => {
    storage.clear()
}