import * as actionTypes from '@/store/actionTypes/dingtalkService'

// 获取钉钉签名参数
export const getJsApiSignature = (params, callback) => ({ type: actionTypes.GET_JS_API_SIGNATURE, params, callback })
export const getJsApiSignatureSuccess = (data) => ({ type: actionTypes.GET_JS_API_SIGNATURE_SUCCESS, data })
export const getJsApiSignatureFailed = () => ({ type: actionTypes.GET_JS_API_SIGNATURE_FAILED })

// 获取钉钉鉴权参数jsapi_ticket
export const getJsApiTicket = (params, callback) => ({ type: actionTypes.GET_JS_API_TICKET, params, callback })
export const getJsApiTicketSuccess = (data) => ({ type: actionTypes.GET_JS_API_TICKET_SUCCESS, data })
export const getJsApiTicketFailed = () => ({ type: actionTypes.GET_JS_API_TICKET_FAILED })