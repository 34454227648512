import * as actionTypes from '@/store/actionTypes/workOrderService'

// 获取 app_id、operation、feature
export const getCascadingMenu = (params, callback) => ({ type: actionTypes.GET_CASCADING_MENU, params, callback })
export const getCascadingMenuSuccess = (data) => ({ type: actionTypes.GET_CASCADING_MENU_SUCCESS, data })
export const getCascadingMenuFailed = () => ({ type: actionTypes.GET_CASCADING_MENU_FAILED })
// 获取页面组件内容
export const getComponents = (params, callback) => ({ type: actionTypes.GET_COMPONENTS, params, callback })
export const getComponentsSuccess = (data) => ({ type: actionTypes.GET_COMPONENTS_SUCCESS, data })
export const getComponentsFailed = () => ({ type: actionTypes.GET_COMPONENTS_FAILED })
// 获取抄送人列表（新建工单）
export const getCCListForCreate = (params, callback) => ({ type: actionTypes.GET_CC_LIST_FOR_CREATE, params, callback })
export const getCCListForCreateSuccess = (data) => ({ type: actionTypes.GET_CC_LIST_FOR_CREATE_SUCCESS, data })
export const getCCListForCreateFailed = () => ({ type: actionTypes.GET_CC_LIST_FOR_CREATE_FAILED })
// 获取审批人列表（新建工单）
export const getApproversForCreate = (params, callback) => ({ type: actionTypes.GET_APPROVERS_FOR_CREATE, params, callback })
export const getApproversForCreateSuccess = (data) => ({ type: actionTypes.GET_APPROVERS_FOR_CREATE_SUCCESS, data })
export const getApproversForCreateFailed = () => ({ type: actionTypes.GET_APPROVERS_FOR_CREATE_FAILED })
// 获取工单详情组件内容（工单详情）
export const getComponentsForDetail = (params, callback) => ({ type: actionTypes.GET_COMPONENTS_FOR_DETAIL, params, callback })
export const getComponentsForDetailSuccess = (data) => ({ type: actionTypes.GET_COMPONENTS_FOR_DETAIL_SUCCESS, data })
export const getComponentsForDetailFailed = () => ({ type: actionTypes.GET_COMPONENTS_FOR_DETAIL_FAILED })
// 新建工单
export const createOrder = (params, callback) => ({ type: actionTypes.CREATE_ORDER, params, callback })
export const createOrderSuccess = (data) => ({ type: actionTypes.CREATE_ORDER_SUCCESS, data })
export const createOrderFailed = () => ({ type: actionTypes.CREATE_ORDER_FAILED })
// 获取审批人列表（工单详情）
export const getApproversForDetail = (params, callback) => ({ type: actionTypes.GET_APPROVERS_FOR_DETAIL, params, callback })
export const getApproversForDetailSuccess = (data) => ({ type: actionTypes.GET_APPROVERS_FOR_DETAIL_SUCCESS, data })
export const getApproversForDetailFailed = () => ({ type: actionTypes.GET_APPROVERS_FOR_DETAIL_FAILED })
// 获取可操作按钮（工单详情）
export const getButtonList = (params, callback) => ({ type: actionTypes.GET_BUTTON_LIST, params, callback })
export const getButtonListSuccess = (data) => ({ type: actionTypes.GET_BUTTON_LIST_SUCCESS, data })
export const getButtonListFailed = () => ({ type: actionTypes.GET_BUTTON_LIST_FAILED })
// 催办
export const orderUrge = (params, callback) => ({ type: actionTypes.ORDER_URGE, params, callback })
export const orderUrgeSuccess = (data) => ({ type: actionTypes.ORDER_URGE_SUCCESS, data })
export const orderUrgeFailed = () => ({ type: actionTypes.ORDER_URGE_FAILED })
// 撤销
export const orderCancel = (params, callback) => ({ type: actionTypes.ORDER_CANCEL, params, callback })
export const orderCancelSuccess = (data) => ({ type: actionTypes.ORDER_CANCEL_SUCCESS, data })
export const orderCancelFailed = () => ({ type: actionTypes.ORDER_CANCEL_FAILED })
// 审批通过
export const orderApproveDone = (params, callback) => ({ type: actionTypes.ORDER_APPROVE_DONE, params, callback })
export const orderApproveDoneSuccess = (data) => ({ type: actionTypes.ORDER_APPROVE_DONE_SUCCESS, data })
export const orderApproveDoneFailed = () => ({ type: actionTypes.ORDER_APPROVE_DONE_FAILED })
// 审批驳回
export const orderApproveReject = (params, callback) => ({ type: actionTypes.ORDER_APPROVE_REJECT, params, callback })
export const orderApproveRejectSuccess = (data) => ({ type: actionTypes.ORDER_APPROVE_REJECT_SUCCESS, data })
export const orderApproveRejectFailed = () => ({ type: actionTypes.ORDER_APPROVE_REJECT_FAILED })
// 执行
export const orderExecute = (params, callback) => ({ type: actionTypes.ORDER_EXECUTE, params, callback })
export const orderExecuteSuccess = (data) => ({ type: actionTypes.ORDER_EXECUTE_SUCCESS, data })
export const orderExecuteFailed = () => ({ type: actionTypes.ORDER_EXECUTE_FAILED })
// 执行驳回
export const orderExecuteReject = (params, callback) => ({ type: actionTypes.ORDER_EXECUTE_REJECT, params, callback })
export const orderExecuteRejectSuccess = (data) => ({ type: actionTypes.ORDER_EXECUTE_REJECT_SUCCESS, data })
export const orderExecuteRejectFailed = () => ({ type: actionTypes.ORDER_EXECUTE_REJECT_FAILED })
// 执行完成
export const orderExecuteDone = (params, callback) => ({ type: actionTypes.ORDER_EXECUTE_DONE, params, callback })
export const orderExecuteDoneSuccess = (data) => ({ type: actionTypes.ORDER_EXECUTE_DONE_SUCCESS, data })
export const orderExecuteDoneFailed = () => ({ type: actionTypes.ORDER_EXECUTE_DONE_FAILED })
// 自动执行
export const orderAutoExecute = (params, callback) => ({ type: actionTypes.ORDER_AUTO_EXECUTE, params, callback })
export const orderAutoExecuteSuccess = (data) => ({ type: actionTypes.ORDER_AUTO_EXECUTE_SUCCESS, data })
export const orderAutoExecuteFailed = () => ({ type: actionTypes.ORDER_AUTO_EXECUTE_FAILED })
// 检测wfid是否有效
export const checkWfidEnable = (params, callback) => ({ type: actionTypes.CHECK_WFID_ENABLE, params, callback })
export const checkWfidEnableSuccess = (data) => ({ type: actionTypes.CHECK_WFID_ENABLE_SUCCESS, data })
export const checkWfidEnableFailed = () => ({ type: actionTypes.CHECK_WFID_ENABLE_FAILED })
// 检测apply_id是否有权限
export const checkApplyIdAuthorized = (params, callback) => ({ type: actionTypes.CHECK_APPLY_ID_AUTHORIZED, params, callback })
export const checkApplyIdAuthorizedSuccess = (data) => ({ type: actionTypes.CHECK_APPLY_ID_AUTHORIZED_SUCCESS, data })
export const checkApplyIdAuthorizedFailed = () => ({ type: actionTypes.CHECK_APPLY_ID_AUTHORIZED_FAILED })
// 获取下一条待操作的工单
export const getNextTaskApplyId = (params, callback) => ({ type: actionTypes.GET_NEXT_TASK_APPLY_ID, params, callback })
export const getNextTaskApplyIdSuccess = (data) => ({ type: actionTypes.GET_NEXT_TASK_APPLY_ID_SUCCESS, data })
export const getNextTaskApplyIdFailed = () => ({ type: actionTypes.GET_NEXT_TASK_APPLY_ID_FAILED })
// 检测自动执行语句是否有效
export const syntaxCheck = (params, callback) => ({ type: actionTypes.SYNTAX_CHECK, params, callback })
export const syntaxCheckSuccess = (data) => ({ type: actionTypes.SYNTAX_CHECK_SUCCESS, data })
export const syntaxCheckFailed = () => ({ type: actionTypes.SYNTAX_CHECK_FAILED })
