import { combineReducers } from 'redux'
import * as actionTypes from '@/store/actionTypes/dingtalkService'

const getJsApiSignature = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.GET_JS_API_SIGNATURE_SUCCESS:
            if (action.data.status === 'success') {
                return  action.data.data
            } else {
                return state
            }
        default:
            return state
    }
}

const getJsApiTicket = (state = '', action) => {
    switch (action.type) {
        case actionTypes.GET_JS_API_TICKET_SUCCESS:
            if (action.data.status === 'success') {
                return  action.data.data.jsapi_ticket
            } else {
                return state
            }
        default:
            return state
    }
}

export default combineReducers({
    jsApiSignature: getJsApiSignature, // 获取钉钉签名参数
    jsApiTicket: getJsApiTicket, // 获取钉钉鉴权参数jsapi_ticket
})