export const readFileAsBuffer = (file) => {
    const reader = new FileReader()
    return new Promise((resolve, reject) => {
        reader.readAsDataURL(file)
        reader.onload = (e) => {
            const base64File = reader.result.replace(/^data:\w+\/\w+;base64,/, '')
            resolve(new OSS.Buffer(base64File, 'base64'))
        }
    })
}

// 将文件名分割成[名字, 后缀]
export const getFileNameAndSuffix = (filename) => {
    const pos = filename.lastIndexOf('.')
    let name = ''
    let suffix = ''
    if (pos !== -1) {
        name = filename.substring(0, pos)
        suffix = filename.substring(pos)
    }
    return [name, suffix]
}

// 现在文件（这里的文件指除图片外的文件，file包括url与name属性）
export const downloadFile = (file) => {
    getBlob(file.url, blob => {
        saveAs(blob, file.name)
    })
}

export const getBlob = (url, cb) => {
    let xhr = new XMLHttpRequest()
    xhr.open('GET', url, true)
    xhr.responseType = 'blob'
    xhr.onload = function() {
        if (xhr.status === 200) {
            cb(xhr.response)
        }
    }
    xhr.send()
}
export const saveAs = (blob, filename) => {
    if (window.navigator.msSaveOrOpenBlob) { // IE
        navigator.msSaveBlob(blob, filename)
    } else {
        let link = document.createElement('a')
        let body = document.querySelector('body')
        link.href = window.URL.createObjectURL(blob) // 创建URL对象
        link.download = filename
        // fix Firefox
        link.style.display = 'none'
        body.appendChild(link)
        link.click()
        body.removeChild(link)
        // window.URL.revokeObjectURL(link.href) // 释放createObjectURL
    }
}
