import { combineReducers } from 'redux'
import * as actionTypes from '@/store/actionTypes/ossService'

const getStsToken = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.GET_STS_TOKEN_SUCCESS:
            if (action.data.status === 'success') {
                return  action.data.data
            } else {
                return state
            }
        default:
            return state
    }
}

const getStsUrl = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.GET_STS_URL_SUCCESS:
            if (action.data.status === 'success') {
                return  action.data.data
            } else {
                return state
            }
        default:
            return state
    }
}

const getUrlList = (state = [], action) => {
    switch (action.type) {
        case actionTypes.GET_URL_LIST_SUCCESS:
            if (action.data.status === 'success') {
                return  action.data.data.file_list
            } else {
                return state
            }
        default:
            return state
    }
}

export default combineReducers({
    stsToken: getStsToken, // 获取oss上传的临时凭证
    stsUrl: getStsUrl, // 获取oss文件下载路径
    urlList: getUrlList, // 获取文件地址列表
})
