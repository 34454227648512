import { put, call, takeEvery } from 'redux-saga/effects'
import * as actionTypes from '@/store/actionTypes/accountService'
import * as actions from '@/store/actions/accountService'
import * as request from './request'

export const accountServiceSaga = [
    takeEvery(actionTypes.LOGIN, login), // 用户登录
    takeEvery(actionTypes.GET_USER_INFO, getUserInfo), // 用户信息
]

export function* getUserInfo(action) {
    try {
        const data = yield call(request.getUserInfo, action)
        action.callback && action.callback(data)
        yield put(actions.getUserInfoSuccess(data))
    } catch(error) {
        yield put(actions.getUserInfoFailed())
    }
}

export function* login(action) {
    try {
        const data = yield call(request.login, action)
        action.callback && action.callback(data)
        yield put(actions.loginSuccess(data))
    } catch(error) {
        yield put(actions.loginFailed())
    }
}