import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createBrowserHistory } from 'history'
import * as dd from 'dingtalk-jsapi'
import * as accountServiceActions from '@/store/actions/accountService'
import * as workOrderServiceActions from '@/store/actions/workOrderService'
import * as ossServiceActions from '@/store/actions/ossService'
import { Button, List, Steps, Card, TextareaItem, ActivityIndicator, Modal, Result, Checkbox, Toast } from 'antd-mobile'
import { Upload } from 'antd'
import { getStorage } from '@/util/storage'
import { getYMD, onWrapTouchStart } from '@/util'
import { getFileNameAndSuffix, downloadFile } from '@/util/files'
import warningIcon from '@/assets/images/warning.svg'
import './index.less'

const history = createBrowserHistory({
    forceRefresh: true
})

class ExecuteDetail extends Component {
    state = {
        current_sor_id: 1, // 当前的节点（等待审批、等待执行、执行中）
        activityIndicatorAnimating: false,
        modalVisible: {},
        modalType: 0,
        platform: 'pc', // pc、android、ios
        modalMessage: {}, // 执行过程中的备注（执行中，执行完成，驳回等）
        fileList: [],
        previewVisible: false,
        previewImage: '',
        previewVideoVisible: false,
        previewVideo: '',
        fileUrlListObj: {}, // 从oss获取的文件地址（授权绝对地址，用来显示）
        modalTitle: '',
        filesVisitedObj: {}, // 附件中已经浏览过的文件对象
        checkApplyIdAuthorizedRequesting: true, // 是否处于获取权限中
        goToNextOrderModalVisible: false,
        currentSecond: 6, // 倒计时开始秒数
        sqlResultModalVisible: false,
        // sql_result: [],
        tableList: [],
        tableColumns: [],
        tableDataSource: [],
        tableRowColWidthPer: 0,
        dry_running: false, // 是否正在预执行
        autoExecuteError: '', // 自动执行预执行返回的错误提示
        checkBoxState: false
    }
    componentDidMount() {
        const { title } = this.props
        dd.biz.navigation.setTitle({ title: title })
        this.getInitialData(() => {
            this.getStsToken()
        })
        setTimeout(() => {
            if (this.state.activityIndicatorAnimating) {
                this.hideIndicatorLoading()
            }
        }, 6000)
        this.setGoBackBtn() // 处理iOS视频预览的情况下，点击返回隐藏预览弹框而不是返回到上一页
    }
    render() {
        const { componentsForDetail, approversForDetail, buttonList, applyIdAuthorized } = this.props.workOrderService
        let { appname, avatar, feature_name, originator, operation_name, page_components, status, status_name } = componentsForDetail
        const { stsToken } = this.props.ossService
        let { platform, fileList, modalTitle, modalVisible, modalType, modalMessage,
            checkApplyIdAuthorizedRequesting, sqlResultModalVisible, tableList, tableRowColWidthPer } = this.state
        const uploadFileProps = {
            className: 'file-upload-container',
            name: 'file',
            listType: 'picture',
            multiple: true,
            showUploadList: { // 是否显示文件信息，false这个文件不显示
                showDownloadIcon: false // 是否显示下载按钮
            },
            fileList: fileList,
            action: `//${stsToken.bucket_name}.${stsToken.endpoint}`,
            beforeUpload: (file) => this.beforeUpload(file),
            onChange: this.changeFile,
            onRemove: this.removeFile,
            transformFile: this.transformFile,
            data: this.getExtraData,
            onPreview: file => this.onPreview(file),
        }

        // stauts: -1未达到、0等待审核、1审核通过、2审核驳回、3撤销、4等待执行、5执行中、6已执行、7执行驳回
        return (
            <div className="page-container detail-page-container">
                {applyIdAuthorized === 1 && <div className="page-view-detail">
                    <div className="operation-detail-row">
                        <Card className="order-title">
                            <Card.Header
                                className="order-title-container"
                                title={<span>
                                    <span>{originator}</span>的工单申请
                                </span>}
                                thumb={avatar ? avatar : null}
                                extra={<span className={`order-status status__${status}`}>{status_name}</span>}
                            />
                            <Card.Body>
                                <List className="me-list-group">
                                    <List.Item className="order-content">
                                        <div className="me-list-title">审批内容</div>
                                        <List.Item.Brief>
                                            <div className="components-container">
                                                <div className="component">
                                                    <div className="component-title">产品：</div>
                                                    <span className="component-content">{appname}</span>
                                                </div>
                                                <div className="component">
                                                    <div className="component-title">业务类型：</div>
                                                    <span className="component-content">{`${operation_name}/${feature_name}`}</span>
                                                </div>
                                                <div className="component">
                                                    <div className="component-title">发起人：</div>
                                                    <span className="component-content">{originator}</span>
                                                </div>
                                                {componentsForDetail && page_components && page_components.length > 0 && this.resetComponents(page_components)}
                                            </div>
                                        </List.Item.Brief>
                                    </List.Item>
                                    <List.Item className="approve-container">
                                        <div className="me-list-title">审批流程</div>
                                        <List.Item.Brief className="vertical">
                                            <Steps direction="vertical">
                                                {approversForDetail.length > 0 && approversForDetail.map((approver) => {
                                                    const { sort_id } = approver
                                                    return <Steps.Step
                                                        key={sort_id}
                                                        icon={sort_id + 1}
                                                        title={this.getHandleName(approver)} // 存在login_name，有指定的操作人，没有则显示部门名字
                                                        description={this.getHandleResult(approver)}
                                                    />
                                                })}
                                            </Steps>
                                        </List.Item.Brief>
                                    </List.Item>
                                </List>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="btns">
                        <div className="btns-container">
                            {buttonList && buttonList.length > 0 && buttonList.map(button =>
                                <Button onClick={() => this.showModal(button)} className={`me-button action-button`} key={button.id}>{button.name}</Button>
                            )}
                        </div>
                    </div>
                </div>}
                {applyIdAuthorized !== 1 && !checkApplyIdAuthorizedRequesting && <div className="page-view-detail">
                    <Result
                        img={<img src={warningIcon} className="am-icon-lg" alt="" />}
                        title="无法查看详情"
                        message="由于未授权，您暂时无法查看该工单详情！"
                    />
                </div>}
                <ActivityIndicator animating={this.state.activityIndicatorAnimating} toast />

                <Modal
                    className="detail-message-modal"
                    visible={modalVisible[modalType]}
                    closable={false}
                    maskClosable={true}
                    platform={platform}
                    wrapProps={(e) => { onTouchStart: onWrapTouchStart(e) }}
                    title={modalTitle}
                    footer={[
                        { text: '取消', onPress: () => { this.closeModal() } },
                        { text: '确认', onPress: () => { this.clickModalOk() } }
                    ]}
                    onClose={this.closeModal}>
                    <div className='modal-content-container'>
                        <List className="me-list-group">
                            {(modalType === 1 || modalType === 2) && <List.Item className='modal-message' key={1}>
                                {`确认${modalTitle}?`}
                            </List.Item>}
                            {modalType !== 1 && modalType !== 2 && <List.Item key={1} className="file-container">
                                <List.Item.Brief>
                                    <TextareaItem
                                        rows={3}
                                        placeholder="请输入备注信息"
                                        onChange={(val) => this.setModalMessage(modalType, val)}
                                        defaultValue={modalType === 6 ? (this.state.autoExecuteError ? this.state.autoExecuteError : '') : ''}
                                        value={modalMessage[modalType]}
                                    ></TextareaItem>
                                </List.Item.Brief>
                            </List.Item>}
                            {/*{modalType === 8 && <List.Item key={1} className="file-container">*/}
                            {/*    {this.state.dry_running ? <List.Item.Brief>*/}
                            {/*        <TextareaItem*/}
                            {/*            rows={3}*/}
                            {/*            placeholder="请输入备注信息"*/}
                            {/*            onChange={(val) => this.setModalMessage(modalType, val)}*/}
                            {/*            value={modalMessage[modalType]}*/}
                            {/*        ></TextareaItem>*/}
                            {/*    </List.Item.Brief> : <div style={{ textAlign: 'center' }}>是否确认开始自动执行预执行？</div>}*/}
                            {/*</List.Item>}*/}
                            {modalType === 7 && <List.Item key={2} className="file-container">
                                <List.Item.Brief>
                                    <div className="radio-group" style={{ paddingLeft: 16, marginTop: 6 }}>
                                        <div onClick={() => {
                                            this.setState({
                                                checkBoxState: !this.state.checkBoxState
                                            })
                                        }} className={`radio ${this.state.checkBoxState ? 'checked' : ''}`}>
                                            <div className="circle"><div className="circle-inner"></div></div>
                                            <div className="title">请确认已手动执行工单</div>
                                        </div>
                                    </div>
                                    {/* <Checkbox
                                        checked={this.state.checkBoxState}
                                        onChange={checked => {
                                            if (checked) {
                                                this.setState({
                                                    checkBoxState: checked
                                                })
                                            } else {
                                                this.setState({
                                                    checkBoxState: false
                                                })
                                            }


                                        }}
                                    >请确认已手动执行工单</Checkbox> */}
                                </List.Item.Brief>
                            </List.Item>
                            }
                            {modalType === 7 && <List.Item key={3} className="file-container">
                                <List.Item.Brief>
                                    <Upload {...uploadFileProps} className={`upload-container ${fileList && fileList.length > 0 ? 'enable' : ''}`}>
                                        <Button className="file-plus">附件↑</Button>
                                    </Upload>
                                </List.Item.Brief>
                            </List.Item>}
                        </List>
                    </div>
                </Modal>

                <Modal className="preview-modal" visible={this.state.previewVisible} closable footer={[]} onClose={this.closePreview}>
                    <img className="preview-image" alt="example" src={this.state.previewImage} />
                </Modal>

                <Modal className="preview-modal" visible={this.state.previewVideoVisible} closable footer={[]} onClose={this.closePreview}>
                    <video className="preview-image" onClick={this.closePreview} src={this.state.previewVideo} playsinline webkit-playsinline></video>
                </Modal>

                <Modal
                    className="go-to-next-order-modal"
                    visible={this.state.goToNextOrderModalVisible}
                    closable={true}
                    maskClosable={false}
                    platform={platform}
                    wrapProps={(e) => { onTouchStart: onWrapTouchStart(e) }}
                    title={'自动跳转提醒'}
                    footer={[
                        { text: '取消', onPress: () => { this.closeModalForNextOrder() } },
                        { text: '立刻跳转', onPress: () => { this.goToNextOrderDirectly() } }
                    ]}
                    onClose={this.closeModalForNextOrder}>
                    <div className='modal-content-container'>
                        <div>倒计时结束后将自动跳转到<span className="next-order-title"> 待处理工单 </span>，如不想自动跳转请按<span className="next-modal-cancel"> 取消 </span>或<span className="close-icon"> X </span>按钮。</div>
                        <div className="count-down-second">{this.state.currentSecond}</div>
                    </div>
                </Modal>

                <Modal
                    className="sql-result-modal"
                    visible={sqlResultModalVisible}
                    width={800}
                    closable={true}
                    maskClosable={false}
                    platform={platform}
                    wrapProps={(e) => { onTouchStart: onWrapTouchStart(e) }}
                    title={'执行结果'}
                    footer={this.setResultModalFooters()} //
                    onClose={this.closeSqlResultModal}>
                    <div className='modal-content-container'>
                        <div className="sql-result request-table">
                            {tableList && tableList.map((row, index) => {
                                return <ul className={index === 0 ? 'header' : 'row'}>
                                    {row.map(col => {
                                        return <li style={{ width: tableRowColWidthPer }}><span>{col}</span></li>
                                    })}
                                </ul>
                            })}
                        </div>
                    </div>
                </Modal>

            </div>
        )
    }

    setResultModalFooters = () => {
        const { dry_running } = this.state
        let buttonList = []
        if (dry_running) { // 预发布中，显示自动执行按钮
            buttonList = [
                { text: '取消', onPress: () => { this.closeSqlResultModal() } },
                {
                    text: '自动执行', onPress: () => {
                        this.orderAutoExecute(res => {
                            const { sql_result } = res.data
                            if (sql_result.length > 0) { // 自动执行后有返回结果
                                this.setTableData(sql_result) // 重新组织列表数据
                                this.setState({
                                    sqlResultModalVisible: true,
                                })
                            } else if (!this.state.dry_running) {// 正式执行成功之后，自动执行后无返回结果，则自动跳转到下一个工单
                                this.showToastForNextOrder()
                            }
                        })
                    }
                }
            ]
        } else {
            buttonList = [
                { text: '确认', onPress: () => { this.closeSqlResultModal() } }
            ]
        }
        return buttonList
    }
    getInitialData = (callback) => {
        const storage = getStorage()
        const { user_token, user_id, user_department, user_name } = storage
        if (user_token && user_id) {
            const { checkApplyIdAuthorized } = this.props.actions
            const queryStr = this.props.location.search
            const apply_id = parseInt(queryStr.split('=')[1])
            this.showIndicatorLoading()
            checkApplyIdAuthorized({
                in_apply_id: apply_id,
                user_id: user_id,
                token: user_token,
                user_name: user_name,
            }, res => {
                this.setState({
                    checkApplyIdAuthorizedRequesting: false,
                })
                setTimeout(() => {
                    this.hideIndicatorLoading()
                }, 500)
                if (res.status === 'success') {
                    const { is_visible } = res.data // 1有权限 0无权限
                    if (is_visible === 1) {
                        this.setState({
                            token: user_token,
                            user_id: user_id,
                            user_name: user_name,
                            apply_id: apply_id,
                            platform: dd.env.platform,
                            user_department: user_department,
                        }, () => {
                            this.getComponentsForDetail()
                            this.getApproversForDetail()
                            this.getButtonList()
                            callback && callback()
                        })
                    }
                } else {
                    this.hideIndicatorLoading()
                }
            })
        }
    }
    resetComponents = (components) => {
        return components.map(component => {
            const { sort_id, component_data, component_name, component_type } = component
            switch (component_type) { // 组件类型：1.主播id组件 2.执行原因textArea组件 3.执行内容组件 4.upload组件 5.抄送组件
                case 1: case 2: case 3: case 5: case 7:
                    return <div className="component" key={sort_id}>
                        <div className="component-title">{component_name}：</div>
                        <span className="component-content"><pre>{component_data}</pre></span>
                    </div>
                case 4: // upload组件
                    const fileList = this.getFileListForComponent(component_data)
                    return component_data && component_data.length > 0 && <div className="component" key={sort_id}>
                        <div className="component-title">{component_name}：</div>
                        <div className="component-content">
                            {fileList.map(file => <div className="file-list-item" key={file.uid}>
                                {
                                    // file.type === 3 && <a href="javascript:void(0);" onClick={() => this.previewImage(file)} className={this.state.filesVisitedObj[file.uid] ? 'visited' : ''}>
                                    //     <div className="image-preview"><img src={file.url} /></div>
                                    //     <div className="image-name">{file.name}</div>
                                    // </a>
                                }
                                {
                                    // file.type !== 3 && <a href="javascript:void(0);" onClick={() => this.downloadFile(file)} className={this.state.filesVisitedObj[file.uid] ? 'visited' : ''}>
                                    //     <div className="image-name">{file.name}</div>
                                    // </a>
                                }
                                <a href="javascript:void(0);" onClick={() => this.onPreview(file, 1)} className={this.state.filesVisitedObj[file.uid] ? 'visited' : ''}>
                                    <div className="image-name">{file.name}</div>
                                </a>
                            </div>)}
                        </div>
                    </div>
                case 6:
                    return component_data && component_data.length > 0 && <div className="component" key={sort_id}>
                        <div className="component-title">{component_name}：</div>
                        <div className="component-content package-content">
                            {component_data.map(package_obj => <div className='packages-container'>
                                <div className='package-item-container'>
                                    <div className='package-item' style={{ flexDirection: 'column' }}>
                                        <div className='package-id'>包id：{package_obj.id}</div>
                                        <div className='package-name'>程序名：{package_obj.name}</div>
                                    </div>
                                </div>
                            </div>)}
                        </div>
                    </div>
                default:
                    return null
            }
        })
    }
    getFileListForComponent = (files) => {
        return files.map(file => {
            let fileInfo = {
                url: file.sign_url,
                name: file.resource_name,
                uid: file.uid,
                status: 'done',
                response: '{"status": "success"}', // 服务端响应内容
                linkProps: '{"download": "image"}', // 下载链接额外的 HTML 属性
            }
            if (file.type) {
                fileInfo.type = file.type
            }
            return fileInfo
        })
    }
    getComponentsForDetail = () => {
        const { getComponentsForDetail } = this.props.actions
        const { token, user_id, apply_id } = this.state
        getComponentsForDetail({
            token,
            user_id: user_id,
            in_apply_id: apply_id,
        })
    }
    getApproversForDetail = () => {
        const { getApproversForDetail } = this.props.actions
        const { token, user_id, apply_id } = this.state
        getApproversForDetail({
            token,
            user_id: user_id,
            in_apply_id: apply_id,
        }, res => {
            if (res.status === 'success') {
                const approversForDetail = res.data.apply_post || []
                for (let i = 0; i < approversForDetail.length; i++) {
                    const { status, sort_id } = approversForDetail[i]
                    // stauts: -1未达到、0等待审核、1审核通过、2审核驳回、3撤销、4等待执行、5执行中、6已执行、7执行驳回
                    if (status === 0 || status === 4 || status === 5) {
                        this.setState({
                            current_sor_id: sort_id
                        })
                    }
                }
            }
        })
    }
    getButtonList = () => {
        const { getButtonList } = this.props.actions
        const { token, user_id, apply_id } = this.state
        getButtonList({
            token,
            user_id: user_id,
            in_apply_id: apply_id,
        })
    }
    getStsToken = () => {
        const { getStsToken } = this.props.actions
        const { token, user_id } = this.state
        getStsToken({
            token,
            user_id
        })
    }
    getHandleName = (approver) => {
        const { user_name } = this.state
        let { login_name: handle_name, node_id, sort_id, depname, update_time, status, handle_type, apply_describe, execute_resource, auto_execute } = approver
        let fileList = execute_resource && execute_resource.length > 0 ? this.getFileListForComponent(execute_resource) : []
        let handle_name_title = '处理人' // node_id === 5 ? '执行人' : '审核人'
        let isExpire = status == 6 && (new Date().getTime() - new Date(update_time).getTime()) > 14 * 24 * 60 * 60 * 1000
        switch (sort_id) {
            case 0:
                handle_name_title = '发起人'
                break
            default:
                // if (status === 3) { // 撤销
                //     handle_name_title = '撤销人'
                //     handle_name = user_name
                // } else {
                //     if (handle_type === 1) { // 1:部门 2:个人
                //         handle_name = depname
                //         handle_name_title = node_id === 5 ? '执行部门' : '审核部门'
                //     } else {
                //         handle_name_title = node_id === 5 ? '执行人' : '审核人'
                //     }
                // }
                if (handle_type === 1) { // 1:部门 2:个人
                    handle_name = depname
                    handle_name_title = node_id === 5 ? '执行部门' : '审核部门'
                } else {
                    handle_name_title = node_id === 5 ? '执行人' : '审核人'
                }
                break
        }
        return (
            <div>
                <div className="name_container">
                    <div className="handle_name_title">{handle_name_title}：</div>
                    <div className="handle_name">{handle_name}{auto_execute && <span style={{ fontSize: 14, color: '#666' }}>（自动执行）</span>}</div>
                </div>
                {apply_describe && <div className="name_container">
                    <div className="describe_title">备注：</div>
                    <div className="describe_content">{apply_describe}</div>
                </div>}
                {fileList && fileList.length > 0 && <div className="name_container">
                    <div className="describe_title">附件：</div>
                    <div className="describe_content">
                        {fileList.map(file => <div className="file-list-item" key={file.uid} style={{ padding: 0 }}>
                            {
                                // file.type === 3 && <a href="javascript:void(0);" onClick={() => this.previewImage(file)} className={this.state.filesVisitedObj[file.uid] ? 'visited' : ''}>
                                //     <div className="image-preview"><img src={file.url} /></div>
                                //     <div className="image-name">{file.name}</div>
                                // </a>
                            }
                            {
                                // file.type !== 3 && <a href="javascript:void(0);" onClick={() => this.downloadFile(file)} className={this.state.filesVisitedObj[file.uid] ? 'visited' : ''}>
                                //     <div className="image-name">{file.name}</div>
                                // </a>
                            }
                            {isExpire ? 
                            <div className="image-name">
                                <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>（已过期）</span>
                                <span className="expire">{file.name}</span>
                            </div> : 
                            <a href="javascript:void(0);" onClick={() => this.onPreview(file, 1)} className={this.state.filesVisitedObj[file.uid] ? 'visited' : ''}>
                                <div className="image-name">{file.name}</div>
                            </a>}
                        </div>)}
                    </div>
                </div>}
            </div>
        )
    }
    getHandleResult = (approver) => {
        // stauts: -1未达到、0等待审核、1审核通过、2审核驳回、3撤销、4等待执行、5执行中、6已执行、7执行驳回
        const { sort_id, create_time, update_time, status, status_name } = approver
        if (sort_id === 0) {
            return <div className={`status__${status}`}>
                {create_time}
            </div>
        } else {
            return <div className={`status__${status}`}>
                {`${status_name}  ${update_time}`}
            </div>
        }
    }
    setModalMessage = (type, val) => {
        let modalMessageObj = {}
        modalMessageObj[type] = val
        this.setState({
            modalMessage: Object.assign(this.state.modalMessage, modalMessageObj)
        })
    }
    closeModal = () => {
        let modalVisible = {}
        modalVisible[this.state.modalType] = false
        this.setState({
            modalVisible: modalVisible
        })
    }
    showModal = (button) => {
        const { id, name } = button
        let modalVisible = {}
        modalVisible[id] = true
        let params = {
            modalVisible: modalVisible,
            modalType: id,
            modalTitle: name
        }
        if (id !== this.state.modalType) { // 当前点击按钮与点击的上一个按钮不是同一个，初始化弹框内容
            params.modalMessage = {}
        }
        this.setState(params)
    }
    // 显示倒计时弹框
    showModalForNextOrder = () => {
        this.setState({
            currentSecond: 6,
            goToNextOrderModalVisible: true
        }, () => {
            const { getNextTaskApplyId } = this.props.actions
            const { token, user_id } = this.state
            const interval = setInterval(() => {
                const currentSecond = this.state.currentSecond - 1
                this.setState({
                    currentSecond: currentSecond,
                    interval,
                })
                if (currentSecond === 0) {
                    clearInterval(interval)
                    getNextTaskApplyId({
                        token,
                        user_id
                    }, res => {
                        if (res.status === 'success') {
                            history.replace(`/app/detail?apply_id=${res.data.apply_id}`)
                        }
                    })
                }
            }, 1000)
        })
    }
    closeModalForNextOrder = (callback) => {
        this.setState({
            goToNextOrderModalVisible: false
        }, () => {
            clearInterval(this.state.interval)
            callback && callback()
        })
    }
    goToNextOrderDirectly = () => {
        this.closeModalForNextOrder(() => {
            const { getNextTaskApplyId } = this.props.actions
            const { token, user_id } = this.state
            getNextTaskApplyId({
                token,
                user_id
            }, res => {
                if (res.status === 'success') {
                    history.replace(`/app/detail?apply_id=${res.data.apply_id}`)
                }
            })
        })
    }
    showToastForNextOrder = (message) => {
        const { getNextTaskApplyId } = this.props.actions
        const { token, user_id } = this.state
        getNextTaskApplyId({
            token,
            user_id
        }, res => {
            if (res.status === 'success') {
                dd.device.notification.toast({
                    text: message ? `${message}，即将跳转到下一个工单！` : '即将跳转到下一个工单！',
                    duration: 1.5,
                    onSuccess: () => {
                        setTimeout(() => {
                            history.replace(`/app/detail?apply_id=${res.data.apply_id}`)
                        }, 1000)
                    }
                })
            }
        })
    }
    closeSqlResultModal = () => {
        this.setState({
            sqlResultModalVisible: false,
        }, () => {
            if (!this.state.dry_running) {
                this.showToastForNextOrder()
            }
            this.setState({
                dry_running: false
            })
        })
    }
    clickModalOk = () => {
        const { modalType, modalMessage, modalTitle, fileList, autoExecuteError } = this.state
        // const describe = modalMessage[modalType] ? modalMessage[modalType].trim() : null
        let describe = null
        if (modalType === 6) {
            describe = modalMessage[modalType] ? modalMessage[modalType].trim() : (autoExecuteError ? autoExecuteError : null)
        } else {
            describe = modalMessage[modalType] ? modalMessage[modalType].trim() : null
        }
        switch (modalType) {
            case 1: // 催办
                this.orderUrge()
                break
            case 2: // 撤销
                this.orderCancel()
                break
            case 3: // 审批通过
                if (describe) {
                    dd.device.notification.confirm({
                        message: `确认${modalTitle}？`,
                        title: "确认",
                        buttonLabels: ['确认', '取消'],
                        onSuccess: res => {
                            const { buttonIndex } = res
                            if (buttonIndex === 0) {
                                this.orderApproveDone(() => {
                                    this.showToastForNextOrder('审批通过')
                                })
                            }
                        }
                    })
                } else {
                    this.orderApproveDone(() => {
                        this.showToastForNextOrder('审批通过')
                    })
                }
                break
            case 4: // 审批驳回
                if (describe) {
                    dd.device.notification.confirm({
                        message: `确认${modalTitle}？`,
                        title: "确认",
                        buttonLabels: ['确认', '取消'],
                        onSuccess: res => {
                            const { buttonIndex } = res
                            if (buttonIndex === 0) {
                                this.orderApproveReject(() => {
                                    this.showToastForNextOrder('审批驳回成功')
                                })
                            }
                        }
                    })
                } else {
                    this.orderApproveReject(() => {
                        this.showToastForNextOrder('审批驳回成功')
                    })
                }
                break
            case 5: // 执行
                if (describe) {
                    dd.device.notification.confirm({
                        message: `确认${modalTitle}？`,
                        title: "确认",
                        buttonLabels: ['确认', '取消'],
                        onSuccess: res => {
                            const { buttonIndex } = res
                            if (buttonIndex === 0) {
                                this.orderExecute()
                            }
                        }
                    })
                } else {
                    this.orderExecute()
                }
                break
            case 6: // 执行驳回
                if (describe) {
                    dd.device.notification.confirm({
                        message: `确认${modalTitle}？`,
                        title: "确认",
                        buttonLabels: ['确认', '取消'],
                        onSuccess: res => {
                            const { buttonIndex } = res
                            if (buttonIndex === 0) {
                                this.orderExecuteReject(describe, () => {
                                    this.showToastForNextOrder('执行驳回成功')
                                })
                            }
                        }
                    })
                } else {
                    this.orderExecuteReject(describe, () => {
                        this.showToastForNextOrder('执行驳回成功')
                    })
                }
                break
            case 7: // 执行完成
                if (!this.state.checkBoxState) {
                    dd.device.notification.toast({
                        text: '请勾选确认已手动执行工单',
                        duration: 2
                    })
                    return
                }
                if (describe || fileList.length > 0) {
                    dd.device.notification.confirm({
                        message: `确认${modalTitle}？`,
                        title: "确认",
                        buttonLabels: ['确认', '取消'],
                        onSuccess: res => {
                            const { buttonIndex } = res
                            if (buttonIndex === 0) {
                                this.orderExecuteDone(() => {
                                    this.showToastForNextOrder('执行完成')
                                })
                            }
                        }
                    })
                } else {
                    this.orderExecuteDone(() => {
                        this.showToastForNextOrder('执行完成')
                    })
                }
                break
            case 8: // 自动执行
                if (describe) {
                    dd.device.notification.confirm({
                        message: `确认${modalTitle}？`,
                        title: "确认",
                        buttonLabels: ['确认', '取消'],
                        onSuccess: res => {
                            const { buttonIndex } = res
                            if (buttonIndex === 0) {
                                this.orderAutoExecute(res => {
                                    const { sql_result } = res.data
                                    if (sql_result.length > 0) { // 自动执行后有返回结果
                                        this.setTableData(sql_result) // 重新组织列表数据
                                        this.setState({
                                            sqlResultModalVisible: true,
                                        })
                                    } else if (!this.state.dry_running) {// 正式执行成功之后，自动执行后无返回结果，则自动跳转到下一个工单
                                        this.showToastForNextOrder()
                                    }
                                })
                            }
                        }
                    })
                } else {
                    this.orderAutoExecute(res => {
                        const { sql_result } = res.data
                        if (sql_result.length > 0) {
                            this.setTableData(sql_result) // 重新组织列表数据
                            this.setState({
                                sqlResultModalVisible: true,
                            })
                        } else if (!this.state.dry_running) {
                            this.showToastForNextOrder()
                        }
                    })
                }
                break
            default:
                break
        }
    }
    setTableData = (list) => {
        if (list && list.length > 0) {
            let tableList = []
            const tableColumns = JSON.parse(JSON.stringify(Object.keys(list[0])))
            tableList = list.map((item, index) => {
                return Object.values(item)
            })
            tableList.unshift(tableColumns)
            this.setState({
                tableList,
                tableRowColWidthPer: `${100 / tableColumns.length}%`
            })
        }
    }
    // // 获取列表头标题
    // getColumnsData = (list) => {
    //     return Object.keys(list[0]).map(key => {
    //         return {
    //             title: key,
    //             dataIndex: key,
    //             key: key
    //         }
    //     })
    // }
    // 催办
    orderUrge = (callback) => {
        const { orderUrge } = this.props.actions
        const { token, user_id, apply_id, current_sor_id } = this.state
        this.closeModal()
        this.showIndicatorLoading()
        orderUrge({
            token,
            user_id,
            in_apply_id: apply_id,
            in_sort_id: current_sor_id
        }, res => {
            this.hideIndicatorLoading()
            if (res.status === 'success') {
                dd.device.notification.toast({
                    text: '催办成功！',
                    duration: 1.5,
                    onSuccess: function () {
                        callback && callback(res)
                    }
                })
            } else {
                this.getComponentsForDetail()
                this.getApproversForDetail()
                this.getButtonList()
            }
        })
    }
    // 撤销
    orderCancel = (callback) => {
        const { orderCancel } = this.props.actions
        const { token, user_id, apply_id, current_sor_id } = this.state
        this.closeModal()
        this.showIndicatorLoading()
        orderCancel({
            token,
            user_id,
            in_apply_id: apply_id,
            in_sort_id: current_sor_id
        }, res => {
            this.hideIndicatorLoading()
            this.getComponentsForDetail()
            this.getApproversForDetail()
            this.getButtonList()
            if (res.status === 'success') {
                dd.device.notification.toast({
                    text: '工单撤销成功！',
                    duration: 1.5, //显示持续时间，单位秒，默认按系统规范[android只有两种(<=2s >2s)]
                })
                callback && callback(res)
            }
        })
    }
    // 审批通过
    orderApproveDone = (callback) => {
        const { orderApproveDone } = this.props.actions
        const { token, user_id, apply_id, current_sor_id, modalMessage, modalType } = this.state
        const describe = modalMessage[modalType] ? modalMessage[modalType].trim() : null
        let params = {
            token,
            user_id,
            in_apply_id: apply_id,
            in_sort_id: current_sor_id,
        }
        if (describe) {
            params.in_apply_describe = describe
        }
        this.closeModal()
        this.showIndicatorLoading()
        orderApproveDone(params, res => {
            this.hideIndicatorLoading()
            this.getComponentsForDetail()
            this.getApproversForDetail()
            this.getButtonList()
            if (res.status === 'success') {
                // dd.device.notification.toast({
                //     text: '审核通过！',
                //     duration: 1.5
                // })
                callback && callback(res)
            }
        })
    }
    // 审批驳回
    orderApproveReject = (callback) => {
        const { orderApproveReject } = this.props.actions
        const { token, user_id, apply_id, current_sor_id, user_name, modalMessage, modalType } = this.state
        const describe = modalMessage[modalType] ? modalMessage[modalType].trim() : null
        let params = {
            token,
            user_id,
            in_apply_id: apply_id,
            in_sort_id: current_sor_id,
            in_execute_name: user_name,
        }
        if (describe) {
            params.in_apply_describe = describe
        }
        this.closeModal()
        this.showIndicatorLoading()
        orderApproveReject(params, res => {
            this.hideIndicatorLoading()
            this.getComponentsForDetail()
            this.getApproversForDetail()
            this.getButtonList()
            if (res.status === 'success') {
                // dd.device.notification.toast({
                //     text: '工单已经被驳回！',
                //     duration: 1.5
                // })
                callback && callback(res)
            }
        })
    }
    // 执行
    orderExecute = (callback) => {
        const { orderExecute } = this.props.actions
        const { token, user_id, apply_id, current_sor_id, user_name, modalMessage, modalType } = this.state
        const describe = modalMessage[modalType] ? modalMessage[modalType].trim() : null
        let params = {
            token,
            user_id,
            in_apply_id: apply_id,
            in_sort_id: current_sor_id,
            in_excute_name: user_name,
        }
        if (describe) {
            params.in_apply_describe = describe
        }
        this.closeModal()
        this.showIndicatorLoading()
        orderExecute(params, res => {
            this.hideIndicatorLoading()
            this.getComponentsForDetail()
            this.getApproversForDetail()
            this.getButtonList()
            if (res.status === 'success') {
                dd.device.notification.toast({
                    text: '开始执行工单！',
                    duration: 1.5
                })
                callback && callback(res)
            }
        })
    }
    // 执行驳回
    orderExecuteReject = (describe, callback) => {
        const { orderExecuteReject } = this.props.actions
        const { token, user_id, apply_id, current_sor_id, user_name } = this.state
        // const describe = modalMessage[modalType] ? modalMessage[modalType].trim() : null
        let params = {
            token,
            user_id,
            in_apply_id: apply_id,
            in_sort_id: current_sor_id,
            in_excute_name: user_name, // 临时需要
        }
        if (describe) {
            params.in_apply_describe = describe
        }
        this.closeModal()
        this.showIndicatorLoading()
        orderExecuteReject(params, res => {
            this.hideIndicatorLoading()
            this.getComponentsForDetail()
            this.getApproversForDetail()
            this.getButtonList()
            if (res.status === 'success') {
                this.setState({
                    autoExecuteError: '', // 驳回成功之后，默认的自动执行预处理错误初始化
                })
                callback && callback(res)
            }
        })
    }
    // 执行完成
    orderExecuteDone = (callback) => {
        const { orderExecuteDone } = this.props.actions
        let { token, user_id, apply_id, current_sor_id, user_name, fileList, modalMessage, modalType } = this.state
        const describe = modalMessage[modalType] ? modalMessage[modalType].trim() : null
        let params = {
            token,
            user_id,
            in_apply_id: apply_id,
            in_sort_id: current_sor_id,
        }
        if (describe) {
            params.in_apply_describe = describe
        }
        if (fileList.length > 0) {
            const newFileList = this.getResource(fileList)
            if (newFileList.length > 0) {
                params.in_execute_resource = newFileList
            }
        }
        this.closeModal()
        this.showIndicatorLoading()
        orderExecuteDone(params, res => {
            this.hideIndicatorLoading()
            this.getComponentsForDetail()
            this.getApproversForDetail()
            this.getButtonList()
            if (res.status === 'success') {
                callback && callback(res)
            }
        })
    }
    // 自动执行
    orderAutoExecute = (callback) => {
        const { orderAutoExecute } = this.props.actions
        const { token, user_id, apply_id, current_sor_id, user_name, modalMessage, modalType, dry_running } = this.state
        const describe = modalMessage[modalType] ? modalMessage[modalType].trim() : null
        let params = {
            token,
            user_id,
            in_apply_id: apply_id,
            in_sort_id: current_sor_id,
            in_excute_name: user_name,
            dry_run: true,
        }
        if (describe) {
            params.in_apply_describe = describe
        }
        this.closeModal()
        this.setState({
            sqlResultModalVisible: false // 以防第二次执行的时候。第一次的result modal未关闭
        }, () => {
            this.showIndicatorLoading()
            if (dry_running) { // 正式执行
                params.dry_run = false // 正式运行
                orderAutoExecute(params, res => {
                    if (res.status === 'success') {
                        this.hideIndicatorLoading()
                        this.getComponentsForDetail()
                        this.getApproversForDetail()
                        this.getButtonList()
                        dd.device.notification.toast({
                            text: '自动执行工单完成！',
                            duration: 1.5
                        })
                        this.setState({
                            dry_running: false // 恢复到预发布状态
                        }, () => {
                            callback && callback(res)
                        })
                    }
                })
            } else { // 预执行
                orderAutoExecute(params, res => { // 试运行
                    this.hideIndicatorLoading()
                    if (res.status === 'success') {
                        this.setState({
                            dry_running: true, // 是否正在预执行
                        }, () => {
                            callback && callback(res)
                        })
                    } else {
                        if (res.detail) {
                            this.setState({
                                autoExecuteError: res.detail,
                            })
                        }
                    }
                })
            }
        })
    }

    getEnableFileList = (fileList) => {
        for (let i = 0; i < fileList.length; i++) {
            if (fileList[i]['status']) {
                if (fileList[i]['status'] === 'error' || fileList[i]['status'] === 'removed') {
                    fileList.splice(i, 1)
                }
            } else {
                fileList.splice(i, 1)
            }
        }
        return fileList
    }
    beforeUpload(file) {
        const isLt20M = file.size / 1024 / 1024 <= 20
        if (!isLt20M) {
            dd.device.notification.toast({
                text: '附件大小不超过20MB！',
                duration: 1.5
            })
            return false
        }
        return isLt20M
    }
    changeFile = ({ fileList }) => {
        fileList = this.getEnableFileList(fileList)
        this.setState({ fileList })
    }
    removeFile = file => {
        this.setState(state => {
            const index = state.fileList.indexOf(file)
            const newFileList = state.fileList.slice()
            newFileList.splice(index, 1)
            return {
                fileList: newFileList,
            }
        })
    }
    transformFile = file => {
        const { stsToken } = this.props.ossService
        const { endpoint, bucket_name } = stsToken
        let fileName = file['name']
        const timestamp = new Date().getTime()
        const fileNameSuffix = getFileNameAndSuffix(fileName)
        fileName = `${fileNameSuffix[0]}_${timestamp}${fileNameSuffix[1]}`
        file.url = `resource/workorder/${getYMD('YYYYMMDD')}/${fileName}`//stsToken.dir + filename;
        file.timestamp = timestamp
        return file
    }
    // upload组件data参数，policy信息
    getExtraData = file => {
        const { stsToken } = this.props.ossService
        const { AccessKeyId, AccessKeySecret, SecurityToken, bucket_name, endpoint } = stsToken

        let timestamp = new Date().getTime()
        timestamp += 172800000 // 2 * 24 * 60 * 60 * 1000
        const newDate = new Date(timestamp)
        newDate.setHours(newDate.getHours(), newDate.getMinutes() - newDate.getTimezoneOffset())
        const utcTime = newDate.toISOString()

        const policyText = {
            expiration: utcTime, // "2020-03-20T12:00:00.000Z", //设置该Policy的失效时间，超过这个失效时间之后，就没有办法通过这个policy上传文件了
            conditions: [
                ["content-length-range", 0, 167772160] // 20MB 设置上传文件的大小限制
            ]
        }
        const policyBase64 = Base64.encode(JSON.stringify(policyText))
        const bytes = Crypto.HMAC(Crypto.SHA1, policyBase64, AccessKeySecret, { asBytes: true })
        const signature = Crypto.util.bytesToBase64(bytes)
        return {
            'key': file.url,
            'OSSAccessKeyId': AccessKeyId,
            'policy': policyBase64,
            'Signature': signature,
            'x-oss-security-token': SecurityToken
        }
    }
    downloadFile = (file) => {
        let filesVisitedObj = {}
        filesVisitedObj[file.uid] = true
        this.setState({
            filesVisitedObj: Object.assign(this.state.filesVisitedObj, filesVisitedObj)
        })
        downloadFile(file)
    }
    previewImage = (file) => {
        let filesVisitedObj = {}
        filesVisitedObj[file.uid] = true
        this.setState({
            filesVisitedObj: Object.assign(this.state.filesVisitedObj, filesVisitedObj)
        })
        dd.biz.util.previewImage({
            urls: [file.url], // 图片地址列表
            current: file.url // 当前显示的图片链接
        })
    }
    onPreview = (file, type) => {
        const { token, user_id, fileUrlListObj, fileList } = this.state
        const { getUrlList } = this.props.actions
        const index = fileList.indexOf(file)
        if (type === 1) { // 预览或者下载component中的文件
            let filesVisitedObj = {}
            filesVisitedObj[file.uid] = true
            this.setState({
                filesVisitedObj: Object.assign(this.state.filesVisitedObj, filesVisitedObj)
            })
            this.previewForComponent(file)
        } else { // 预览或者下载Upload中的文件
            if (fileUrlListObj && fileUrlListObj[index]) { // 存在缓存文件url列表
                this.previewForUpload(file, fileUrlListObj[index])
            } else { // 没有缓存，直接从接口获取文件url列表
                getUrlList({
                    token,
                    user_id,
                    file_list: [file.url],
                }, res => {
                    if (res.status === 'success') {
                        const urlList = res.data.file_list
                        const fileUrl = urlList[0]
                        let fileUrlListTmp = {}
                        fileUrlListTmp[index] = fileUrl
                        this.setState({
                            fileUrlListObj: Object.assign(this.state.fileUrlListObj, fileUrlListTmp)
                        }, () => {
                            this.previewForUpload(file, fileUrl)
                        })
                    }
                })

            }
        }
    }
    previewForUpload = (file, fileUrl) => { // file: url为相对路径，fileUrl为该file的绝对路径
        const { platform } = this.state
        const fileNameSuffix = getFileNameAndSuffix(file.name)
        let fileSuffix = fileNameSuffix[1]
        fileSuffix = fileSuffix.toLowerCase()
        if (file.type.indexOf('image/') >= 0) { // 图片类型文件
            dd.biz.util.previewImage({
                urls: [fileUrl], // 图片地址列表
                current: fileUrl // 当前显示的图片链接
            })
        } else { // 非图片类型文件
            if (platform === 'ios' || platform === 'android') { // ios android
                if (file.type.indexOf('video/') >= 0) { // 视频格式
                    let videoTypeSupport = ['.mov', '.mp4', '.3gp', '.mpv'] // MOV、MP4、3GP、MPV
                    videoTypeSupport = videoTypeSupport.join()
                    if (videoTypeSupport.indexOf(fileSuffix) >= 0) {
                        if (platform === 'ios') { // ios
                            this.setState({
                                previewVideo: fileUrl,
                                previewVideoVisible: true
                            }, () => {
                                this.setGoBackBtn()
                            })
                        } else { // android
                            dd.device.notification.toast({
                                text: '请在PC端查看！',
                                duration: 1.5
                            })
                        }
                    } else {
                        dd.device.notification.toast({
                            text: '不支持该视频格式，请在PC端查看！',
                            duration: 1.5
                        })
                    }
                } else { // 非视频格式（.xls .xlsx .doc .docx .sql .txt...）
                    let textTypeSupport = ['.xls', '.xlsx', '.doc', '.docx', '.txt', '.pdf']
                    textTypeSupport = textTypeSupport.join()
                    if (textTypeSupport.indexOf(fileSuffix) >= 0) {
                        downloadFile(file)
                    } else {
                        dd.device.notification.toast({
                            text: '不支持该文件格式的预览！',
                            duration: 1.5
                        })
                    }
                }
            } else { // pc
                downloadFile(file)
            }
        }
    }
    previewForComponent = (file) => {
        const { platform } = this.state
        const fileUrl = file.url
        const fileType = file.type
        const fileNameSuffix = getFileNameAndSuffix(file.name)
        let fileSuffix = fileNameSuffix[1]
        fileSuffix = fileSuffix.toLowerCase()
        if (fileType === 3) { // 图片类型文件
            dd.biz.util.previewImage({
                urls: [fileUrl], // 图片地址列表
                current: fileUrl // 当前显示的图片链接
            })
        } else { // 非图片类型文件
            if (fileType === 5) { // 视频格式
                if (platform === 'ios') { // ios
                    let videoTypeSupport = ['.mov', '.mp4', '.3gp', '.mpv'] // MOV、MP4、3GP、MPV
                    videoTypeSupport = videoTypeSupport.join()
                    if (videoTypeSupport.indexOf(fileSuffix) >= 0) {
                        this.setState({
                            previewVideo: fileUrl,
                            previewVideoVisible: true
                        }, () => {
                            this.setGoBackBtn()
                        })
                    } else {
                        dd.device.notification.toast({
                            text: '不支持该视频格式，请在PC端查看！',
                            duration: 1.5
                        })
                    }
                } else if (platform === 'android') { // android
                    dd.device.notification.toast({
                        text: '请在PC端查看！',
                        duration: 1.5
                    })
                } else { // pc
                    downloadFile(file)
                }
            } else { // 其他格式（.xls .xlsx .doc .docx .sql .txt...）
                let textTypeSupport = ['.xls', '.xlsx', '.doc', '.docx', '.txt', '.pdf']
                textTypeSupport = textTypeSupport.join()
                if (platform === 'ios' || platform === 'android') { // ios android
                    if (textTypeSupport.indexOf(fileSuffix) >= 0) {
                        dd.biz.util.openLink({
                            url: file.url
                        })
                    } else {
                        dd.device.notification.toast({
                            text: '不支持该文件格式，请在PC端查看！',
                            duration: 1.5
                        })
                    }
                } else { // pc
                    downloadFile(file)
                }
            }
        }
    }
    closePreview = () => {
        const { previewVisible, previewVideoVisible } = this.state
        if (previewVisible) {
            this.setState({ previewVisible: false })
        }
        if (previewVideoVisible) {
            this.setState({ previewVideoVisible: false }, () => {
                dd.biz.navigation.setLeft({
                    control: false,
                    text: '',
                    onSuccess: function (result) { },
                    onFail: function (err) { }
                })
            })
        }
    }
    // 将原附件文件转为上传所需的资源格式（资源类型：1.excel(.xls .xlsx) 2.sql文件(.sql .mdf ...) 3.图片 4.压缩包rar' 5视频类型（iphone支持MOV、MP4、3GP、MPV四种格式））
    getResource = (fileList) => {
        const resource = fileList.map(file => {
            let resource_info = {
                resource_url: file.url,
                resource_name: file.name,
                uid: file.uid,
                timestamp: file.timestamp,
            }
            if (file.type.indexOf('image/') >= 0) {
                resource_info.type = 3
            } else if (file.type.indexOf('video/') >= 0) {
                resource_info.type = 5
            }
            return resource_info
        })
        return resource
    }
    setGoBackBtn = () => {
        const _this = this
        if (dd.env.platform === 'ios') {
            if (this.state.previewVideoVisible) {
                dd.biz.navigation.setLeft({
                    control: true,// 是否控制点击事件，true控制，false不控制，默认false
                    text: '', // 控制显示文本，空字符串表示显示默认文本
                    onSuccess: function (result) {
                        // 如果control为true，则onSuccess将在发生按钮点击事件被回调
                        _this.setState({ previewVideoVisible: false }, () => {
                            dd.biz.navigation.setLeft({
                                control: false,
                                text: '',
                                onSuccess: function (result) { },
                                onFail: function (err) { }
                            })
                        })
                    },
                    onFail: function (err) { }
                })
            } else {
                dd.biz.navigation.setLeft({
                    control: false,
                    text: '',
                    onSuccess: function (result) { },
                    onFail: function (err) { }
                })
            }
        }
    }
    showIndicatorLoading = () => {
        this.setState({ activityIndicatorAnimating: true }, () => {
            setTimeout(() => {
                if (this.state.activityIndicatorAnimating) {
                    this.setState({ activityIndicatorAnimating: false })
                }
            }, 6000)
        })
    }
    hideIndicatorLoading = () => {
        this.setState({ activityIndicatorAnimating: false })
    }
}

const mapStateToProps = (state) => ({
    accountService: state.accountService,
    workOrderService: state.workOrderService,
    ossService: state.ossService,
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Object.assign({}, accountServiceActions, workOrderServiceActions, ossServiceActions), dispatch)
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExecuteDetail)
