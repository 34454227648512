export const GET_STS_TOKEN = 'GET_STS_TOKEN'
export const GET_STS_TOKEN_SUCCESS = 'GET_STS_TOKEN_SUCCESS'
export const GET_STS_TOKEN_FAILED = 'GET_STS_TOKEN_FAILED'

export const GET_STS_URL = 'GET_STS_URL'
export const GET_STS_URL_SUCCESS = 'GET_STS_URL_SUCCESS'
export const GET_STS_URL_FAILED = 'GET_STS_URL_FAILED'

export const GET_URL_LIST = 'GET_URL_LIST'
export const GET_URL_LIST_SUCCESS = 'GET_URL_LIST_SUCCESS'
export const GET_URL_LIST_FAILED = 'GET_URL_LIST_FAILED'
