import axios from 'axios'
import { removeNull } from '@/util'
import { getBaseApi } from '@/util/config'
import * as dd from 'dingtalk-jsapi'

export const request = (method, url, params) => {
    if (params) {
        params = removeNull(params)
    }
    console.log('client request', `${getBaseApi()}${url}`, method, JSON.stringify(params))
    return new Promise((resolve, reject) => {
        axios({
            method: method,
            url: url,
            data: method === 'POST' ? (params ? params : null) : null,
            params: method === 'GET' ? (params ? params : null) : null,
            baseURL: getBaseApi(),
        }).then(res => {
            if (res.data.status === 'error') {
                if (res.data.type === 'timeout') {
                    dd.device.notification.toast({
                        text: `请求超时！请重试！（${url}）`,
                        duration: 2
                    })
                } else if (res.data.type === 'token expired') {
                    // dd.device.notification.toast({
                    //     text: 'Token失效！',
                    //     duration: 2,
                    //     onSuccess: function () {
                    //         // window.location.href = '/'
                    //         window.location.reload()
                    //     }
                    // })
                    setTimeout(() => { // 延迟刷新页面
                        window.location.reload()
                    }, 2000)
                } else {
                    // message.error(res.data.detail)
                    dd.device.notification.toast({
                        text: res.data.detail,
                        duration: 2
                    })
                }
            }
            resolve(res.data || {})
        }).catch(err => {
            // message.error('请求出错，请重试！')
            console.log('client request err', err)
            if (!dd.isToastShowing) {
                dd.device.notification.toast({
                    text: `请求出错，请重试！${url}`,
                    duration: 2,
                    onSuccess: () => {
                        dd.isToastShowing=true
                        setTimeout(() => {
                            dd.isToastShowing=false
                        })
                    }
                })
            }
            reject(err)
        })
    })
}

export default {
    get: function (url, params) {
        return request('GET', url, params)
    },
    post: function (url, params) {
        return request('POST', url, params)
    }
}
