import { combineReducers } from 'redux'
import * as actionTypes from '@/store/actionTypes/workOrderService'

const getCascadingMenu = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.GET_CASCADING_MENU_SUCCESS:
            if (action.data.status === 'success') {
                const { app_list, operation_list, feature_list } = action.data.data
                return app_list || operation_list || feature_list
            } else {
                return state
            }
        default:
            return state
    }
}

const getComponents = (state = [], action) => {
    switch (action.type) {
        case actionTypes.GET_COMPONENTS_SUCCESS:
            if (action.data.status === 'success') {
                return action.data.data.apply_post
            } else {
                return state
            }
        default:
            return state
    }
}

const getCCListForCreate = (state = [], action) => {
    switch (action.type) {
        case actionTypes.GET_CC_LIST_FOR_CREATE_SUCCESS:
            if (action.data.status === 'success') {
                return action.data.data.apply_post
            } else {
                return state
            }
        default:
            return state
    }
}

const getApproversForCreate = (state = [], action) => {
    switch (action.type) {
        case actionTypes.GET_APPROVERS_FOR_CREATE_SUCCESS:
            if (action.data.status === 'success') {
                return  action.data.data.apply_post
            } else {
                return state
            }
        default:
            return state
    }
}

const getComponentsForDetail = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.GET_COMPONENTS_FOR_DETAIL_SUCCESS:
            if (action.data.status === 'success') {
                return action.data.data.apply_post
            } else {
                return state
            }
        default:
            return state
    }
}

const createOrder = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.CREATE_ORDER_SUCCESS:
            if (action.data.status === 'success') {
                return action.data.data.apply_post
            } else {
                return state
            }
        default:
            return state
    }
}

const getApproversForDetail = (state = [], action) => {
    switch (action.type) {
        case actionTypes.GET_APPROVERS_FOR_DETAIL_SUCCESS:
            if (action.data.status === 'success') {
                return action.data.data.apply_post
            } else {
                return state
            }
        default:
            return state
    }
}

const getButtonList = (state = [], action) => {
    switch (action.type) {
        case actionTypes.GET_BUTTON_LIST_SUCCESS:
            if (action.data.status === 'success') {
                return action.data.data.user_menu
            } else {
                return state
            }
        default:
            return state
    }
}

const orderUrge = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.ORDER_URGE_SUCCESS:
            if (action.data.status === 'success') {
                return action.data.data
            } else {
                return state
            }
        default:
            return state
    }
}
const orderCancel = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.ORDER_CANCEL_SUCCESS:
            if (action.data.status === 'success') {
                return action.data.data
            } else {
                return state
            }
        default:
            return state
    }
}
const orderApproveDone = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.ORDER_APPROVE_DONE_SUCCESS:
            if (action.data.status === 'success') {
                return action.data.data
            } else {
                return state
            }
        default:
            return state
    }
}
const orderApproveReject = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.ORDER_APPROVE_REJECT_SUCCESS:
            if (action.data.status === 'success') {
                return  action.data.data
            } else {
                return state
            }
        default:
            return state
    }
}
const orderExecute = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.ORDER_EXECUTE_SUCCESS:
            if (action.data.status === 'success') {
                return action.data.data
            } else {
                return state
            }
        default:
            return state
    }
}
const orderExecuteReject = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.ORDER_EXECUTE_REJECT_SUCCESS:
            if (action.data.status === 'success') {
                return action.data.data
            } else {
                return state
            }
        default:
            return state
    }
}
const orderExecuteDone = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.ORDER_EXECUTE_DONE_SUCCESS:
            if (action.data.status === 'success') {
                return action.data.data
            } else {
                return state
            }
        default:
            return state
    }
}
const orderAutoExecute = (state = [], action) => {
    switch (action.type) {
        case actionTypes.ORDER_AUTO_EXECUTE_SUCCESS:
            if (action.data.status === 'success') {
                return action.data.sql_result
            } else {
                return state
            }
        default:
            return state
    }
}

const checkWfidEnable = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.CHECK_WFID_ENABLE_SUCCESS:
            if (action.data.status === 'success') {
                return action.data.data
            } else {
                return state
            }
        default:
            return state
    }
}

const checkApplyIdAuthorized = (state = 0, action) => {
    switch (action.type) {
        case actionTypes.CHECK_APPLY_ID_AUTHORIZED_SUCCESS:
            if (action.data.status === 'success') {
                return action.data.data.is_visible
            } else {
                return state
            }
        default:
            return state
    }
}

const getNextTaskApplyId = (state = 0, action) => {
    switch (action.type) {
        case actionTypes.GET_NEXT_TASK_APPLY_ID_SUCCESS:
            if (action.data.status === 'success') {
                return action.data.data.apply_id
            } else {
                return state
            }
        default:
            return state
    }
}

const syntaxCheck = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.SYNTAX_CHECK_SUCCESS:
            if (action.data.status === 'success') {
                return action.data.data
            } else {
                return state
            }
        default:
            return state
    }
}

export default combineReducers({
    cascadingMenu: getCascadingMenu, // 获取 app_id、operation、feature
    components: getComponents, // 获取页面组件内容
    ccListForCreate: getCCListForCreate, // 获取抄送人列表（新建工单）
    approversForCreate: getApproversForCreate, // 获取审批人列表（新建工单）
    componentsForDetail: getComponentsForDetail, // 获取工单详情组件内容（工单详情）
    createOrderResult: createOrder, // 新建工单
    approversForDetail: getApproversForDetail, // 获取审批人列表（工单详情）
    buttonList: getButtonList, // 获取可操作按钮（工单详情）
    orderUrgeResult: orderUrge, // 催办
    orderCancelResult: orderCancel, // 撤销
    orderApproveDoneResult: orderApproveDone, // 审批通过
    orderApproveRejectResult: orderApproveReject, // 审批驳回
    orderExecuteResult: orderExecute, // 执行
    orderExecuteRejectResult: orderExecuteReject, // 执行驳回
    orderExecuteDoneResult: orderExecuteDone, // 执行完成
    orderAutoExecuteResult: orderAutoExecute, // 自动执行
    wfidEnable: checkWfidEnable, // 检测wfid是否有效
    applyIdAuthorized: checkApplyIdAuthorized, // 检测apply_id是否有权限
    nextTaskApplyId: getNextTaskApplyId, // 获取下一条待操作的工单
    syntaxCheckResult: syntaxCheck, // 检测自动执行语句是否有效
})
