import * as actionTypes from '@/store/actionTypes/accountService'

// 用户登录
export const login = (params, callback) => ({ type: actionTypes.LOGIN, params, callback })
export const loginSuccess = (data) => ({ type: actionTypes.LOGIN_SUCCESS, data })
export const loginFailed = () => ({ type: actionTypes.LOGIN_FAILED })

// 用户信息
export const getUserInfo = (params, callback) => ({ type: actionTypes.GET_USER_INFO, params, callback })
export const getUserInfoSuccess = (data) => ({ type: actionTypes.GET_USER_INFO_SUCCESS, data })
export const getUserInfoFailed = () => ({ type: actionTypes.GET_USER_INFO_FAILED })