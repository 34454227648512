import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createBrowserHistory } from 'history'
import * as accountServiceActions from '@/store/actions/accountService'
import * as workOrderServiceActions from '@/store/actions/workOrderService'
import * as dingtalkServiceActions from '@/store/actions/dingtalkService'
import * as ossServiceActions from '@/store/actions/ossService'
import * as dbServiceActions from '@/store/actions/dbService'
import * as dd from 'dingtalk-jsapi'
import { Button, List, InputItem, TextareaItem, Tag, Steps, Picker, ActivityIndicator, Modal, Icon } from 'antd-mobile'
import { createForm } from 'rc-form'
import { getStorage } from '@/util/storage'
import { getYMD, objToArray } from '@/util'
import { setDDConfig } from '@/util/ding'
import { getAppKey, getAppId, getCorpId } from '@/util/config'
import { getFileNameAndSuffix, downloadFile } from '@/util/files'
import { Upload, Radio } from 'antd'
import './index.less'

const history = createBrowserHistory({
    forceRefresh: true
})

if (!Object.values) {
    Object.values = objToArray
}

class SubmitContent extends Component {
    state = {
        user_id: null,
        app_name: '',
        operation_name: '',
        feature_name: '',
        pictures: [], // 图片
        platform: 'pc', // pc、android、ios
        fileList: [], // 上传附件列表
        approver_list: [], // 审批人
        approversForCreate: [], // 创建工单时的审批人列表
        ccList: [],
        ccDefaultList: [], // 默认抄送人
        pickedCCDefaultList: [], // 已选择的默认抄送对象
        pickedDepartments: [], // 已选择的部门id列表
        pickedUsers: [], // 已选择的用户id列表
        pickedCombinedDepartments: [], // 自定义组
        activityIndicatorAnimating: false,
        component_text_val_list: [],
        component_text_val_list_obj: {},
        components_error: false,
        approvers_error: true,
        database_error: true,
        previewVisible: false,
        previewImage: '',
        previewVideoVisible: false,
        previewVideo: '',
        fileUrlListObj: {}, // 从oss获取的文件地址（授权绝对地址，用来显示）
        component_key_value_obj: {}, // 可改变的组件（针对文案）内容对象
        chooseData: [], // 审批人
        approversModalVisible: false,
        approversModalTitle: '选择',
        approversModalType: null, // 0, 1, 2
        approvers_node_sort_id: null,
        approvers_node_user_id_name: {},
        executeType: 2, // 1：手动 2：自动 默认非选中
        database: '', // 选择的数据库
        databaseModalVisible: false,
        databaseList: [],
        sql_string: '',
        is_auto_execute: false, // 接口返回是否可以自动执行
        package_list: [{
            id: null,
            name: null
        }],
        package_components_error: false,
        isPackageComponentVisible: false,
        files_error: false,
        files_contained: false,
        deploy_address: '公有云',
        sql_string_error_result: '',
    }
    componentDidMount() {
        const { title } = this.props

        const { getComponents, getCCListForCreate, getApproversForCreate, getJsApiSignature, getStsToken, getAllInstance } = this.props.actions
        dd.biz.navigation.setTitle({ title: title })

        const storage = getStorage()
        let { action_params, user_token, user_id, cascadingMenuParams, user_department } = storage
        user_department = JSON.parse(user_department)
        if (user_token && user_id) {
            this.setState({
                token: user_token,
                user_id: user_id,
                user_department_id: user_department[0],
            })
            const queryStr = this.props.location.search
            const app_name_code = queryStr.split('=')[1]
            const { app_name, operation_name, feature_name, wfid, appid, operation_id, feature_id } = JSON.parse(action_params)
            this.setState({ app_name, operation_name, feature_name, wfid, platform: dd.env.platform, appid, operation_id, feature_id})
            if (wfid >= 0) {
                // 获取组件
                getComponents({ in_wfid: wfid, token: user_token, user_id: user_id }, res => {
                    if (res.status === 'success') {
                        const { auto_execute_type, apply_post } = res.data
                        if (auto_execute_type) {
                            this.setState({
                                is_auto_execute: true,
                            })
                        }

                        let isPackageComponentVisible = false
                        let components_error_count = 0
                        let files_error = false
                        let files_contained = false

                        for (let i = 0; i < apply_post.length; i++) {
                            if (apply_post[i].component_type === 6) { // 程序发布必传 包id/程序名是否完整
                                isPackageComponentVisible = true
                            }
                            if (apply_post[i].component_type === 4 && apply_post[i].contains_content === 1) { // 附件必传 附件个数是否>0
                                files_contained = true
                            }
                            if (apply_post[i].component_type < 4 && apply_post[i].contains_content === 1) { // 必传文本
                                components_error_count += 1
                            }
                        }
                        if (components_error_count > 0) {
                            this.setState({
                                components_error: true
                            })
                        }

                        if (isPackageComponentVisible) {
                            this.setState({
                                package_components_error: true,
                                isPackageComponentVisible
                            })
                        }

                        if (files_contained) {
                            this.setState({
                                files_error: true,
                                files_contained: true,
                            })
                        }
                    }
                })
                // 获取抄送人
                getCCListForCreate({ in_wfid: wfid, token: user_token, user_id: user_id }, res => {
                    if (res.status === 'success') {
                        const ccDefaultList = res.data.apply_post
                        const pickedCCDefaultList = this.getPickedIdList(ccDefaultList)
                        this.setState({
                            ccList: ccDefaultList,
                            ccDefaultList: ccDefaultList,
                            pickedCCDefaultList: pickedCCDefaultList,
                        })
                    }
                })
                // 获取审批人
                getApproversForCreate({
                    in_wfid: wfid,
                    token: user_token,
                    user_id: user_id
                }, res => {
                    if (res.status === 'success') {
                        let approversForCreate = res.data.apply_post
                        for (let i = 0; i < approversForCreate.length; i++) {
                            approversForCreate[i]['new_handle_name'] = approversForCreate[i]['handle_name']
                            approversForCreate[i]['new_handle_userid'] = approversForCreate[i]['handle_userid']
                        }
                        this.setState({ approversForCreate: approversForCreate })
                    }
                })
                // 钉钉鉴权
                getJsApiSignature({
                    url: window.location.href,
                    app_key: getAppKey(),
                })
                getStsToken({
                    token: user_token,
                    user_id,
                })
                if (app_name_code != null) { // 应用名存在
                    this.setState({
                        app_name_code
                    })
                    // 获取应用对应的数据库
                    getAllInstance({
                        token: user_token,
                        user_id,
                        app_name: app_name_code,
                    }, res => {
                        if (res.status === 'success') {
                            let databaseList = res.data.message
                            if (databaseList && databaseList.length > 0) {
                                databaseList.sort((x, y) => {return x.instance_name.localeCompare(y.instance_name)}) // 数据库排序显示
                                this.setState({ databaseList })
                            } else { // 如果数据库没有数据。则判定为手动执行
                                this.setState({ database_error: false })
                            }
                        }
                    })
                }
            } else {
                dd.device.notification.toast({
                    text: '请选择审批业务',
                    duration: 2,
                    onSuccess : function(res) {
                        this.props.history.push('/app/submit/actions')
                    },
                    onFail : function(err) {}
                })
            }
        }
    }
    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }
    render() {
        let { components } = this.props.workOrderService
        let { app_name, app_name_code, operation_name, feature_name, approversForCreate, components_error,
            approvers_error, database_error, is_auto_execute, chooseData, platform, executeType, database,
            databaseList, package_components_error, files_error } = this.state
        return (
            <div className="page-container operation-page-container">
                <div className="page-view-content">
                    <div className="operation-detail-row">
                        <div className="order-title">{`${app_name}/${operation_name}/${feature_name}`}</div>
                        <List className="me-list-group">
                            { this.resetComponents(components) }
                            {is_auto_execute && app_name_code && databaseList.length > 0 && <List.Item className="execute-type-content">
                                <div className="me-list-title necessary">执行方式</div>
                                <div className="radio-group" style={{ paddingLeft: 16, marginTop: 6 }}>
                                    <div onClick={() => this.changeExecuteType(1)} className={`radio ${executeType === 1 ? 'checked' : ''}`}>
                                        <div className="circle"><div className="circle-inner"></div></div>
                                        <div className="title">手动</div>
                                    </div>
                                    <div onClick={() => this.changeExecuteType(2)} className={`radio ${executeType === 2 ? 'checked' : ''}`}>
                                        <div className="circle"><div className="circle-inner"></div></div>
                                        <div className="title">自动<span style={{fontSize: 14, color: '#666'}}>（修改内容必须为可执行SQL语句）</span></div>
                                    </div>
                                </div>
                                {platform === 'pc' && executeType === 2 &&
                                    <div className="me-list-title db-title">数据库：
                                        {database ? <div onClick={this.showDataBaseModal} className="db-name pointer">{database}<div className="db-icon"><Icon type={'down'} /></div></div> : <div onClick={this.showDataBaseModal} className="db-name placeholder pointer">请选择<div className="db-icon"><Icon type={'down'} /></div></div>}
                                    </div>
                                }
                                {platform !== 'pc' && executeType === 2 && <Picker
                                        cols={1}
                                        data={databaseList.map(db => ({ label: db.instance_name, value: db.instance_name}))}
                                        onChange={value => this.chooseDataBase(value)}
                                        // extra={database ? <span className="db-name">{database} <Icon type={'down'} /></span> : <span className="db-name placeholder">请选择 <Icon type={'down'} /></span>}
                                    >
                                        <div className="me-list-title db-title">数据库：
                                            {database ? <div className="db-name pointer">{database}<div className="db-icon"><Icon type={'down'} /></div></div> : <span className="db-name placeholder pointer">请选择<div className="db-icon"><Icon type={'down'} /></div></span>}
                                        </div>
                                    </Picker>
                                }
                            </List.Item>}
                            <List.Item className="approve-container">
                                <div className="me-list-title necessary">审批流程</div>
                                <List.Item.Brief>
                                    <Steps
                                        className="approve-list-steps-container"
                                        direction={'vertical'}>
                                        {approversForCreate.length > 0 && approversForCreate.map((approver) => {
                                            /**
                                             * node_id: 0:数据等级匹配(发起时审批) 1:发起方审核(发起方审核/本部门审核) 2:执行方审核(执行方审核) 3:测试方审核(发布时测试审核即可)
                                             * 4:top_leader审核(发哥和张总审核：涉及实名认证及货币的业务) 5:执行方执行 6:巡管审核(运营发起申请清除主播权限时，需要审核)
                                             */
                                            return this.getHandleName(approver) // 设置审批流程title与部门
                                        })}
                                    </Steps>
                                </List.Item.Brief>
                            </List.Item>
                        </List>
                    </div>
                    <div className="btns">
                        <div className="btns-container">
                            <Button onClick={() => this.submit()} disabled={components_error || approvers_error || (is_auto_execute && database_error) || package_components_error || files_error} className={`me-button action-button ${(components_error || approvers_error || (is_auto_execute && database_error) || package_components_error || files_error)? 'disabled' : ''}`}  style={{ width: '100%', borderRight: 'initial', borderBottom: 'initial' }} >提交</Button>
                        </div>
                    </div>
                </div>
                <ActivityIndicator animating={this.state.activityIndicatorAnimating} toast />
                <Modal className="preview-modal" visible={this.state.previewVisible} closable footer={[]} onClose={this.closePreview}>
                  <img className="preview-image" onClick={this.closePreview} src={this.state.previewImage} />
                </Modal>
                <Modal className="preview-modal" visible={this.state.previewVideoVisible} closable footer={[]} onClose={this.closePreview}>
                    <video className="preview-image" onClick={this.closePreview} src={this.state.previewVideo} ></video>
                </Modal>

                <Modal
                    className="approver-modal"
                    title={this.state.approversModalTitle}
                    visible={platform === 'pc' && this.state.approversModalVisible}
                    closable
                    onClose={this.closeApproversModal}
                >
                    <List className="approver-list">
                        {chooseData.map((data, index) => <List.Item
                            key={index}
                            onClick={() => this.clickApproverListItem(data)}
                        >
                            {this.getListTitleFromApprover(data)}
                        </List.Item>)}
                    </List>
                </Modal>

                <Modal
                    className="database-modal"
                    title={'选择数据库'}
                    visible={platform === 'pc' && this.state.databaseModalVisible}
                    closable
                    onClose={this.closeDataBaseModal}
                >
                    <List className="db-list">
                        {databaseList.map((db, index) => <List.Item
                            key={index}
                            onClick={() => this.chooseDataBase(db.instance_name)}
                        >
                            {db.instance_name}
                        </List.Item>)}
                    </List>
                </Modal>
            </div>
        )
    }
    // 判断package_list组件中数据是否存在未填项
    isPackageListError = () => {
        const package_list = this.state.package_list
        let error_count = 0
        for (let i = 0; i < package_list.length; i++) {
            const package_obj = package_list[i]
            if (package_obj.id === null || package_obj.name === null) {
                console.log()
                error_count += 1
            } else if (package_obj.id !== null && !package_obj.id.trim()) {
                error_count += 1
            } else if (package_obj.name !== null && !package_obj.name.trim()) {
                error_count += 1
            }
        }
        if (error_count >= 1) {
            return true
        } else {
            return false
        }
    }
    // 点击审批人弹框中的某个审批人（或者部门）
    clickApproverListItem = (id_name) => { // id_name: {label: name, value: user_id/dep_id}
        const { approversModalType, approvers_node_sort_id, approvers_node_user_id_name  } = this.state
        if (approversModalType === 2) { // 选择个人
            this.chooseApproverForOneDep([id_name.value], approvers_node_sort_id, approvers_node_user_id_name)
        } else { // 选择部门 0，1
            this.chooseApproverForDeps([id_name.value], approvers_node_sort_id, approvers_node_user_id_name)
        }
    }
    // 审批人弹框中显示每一个名字（或者部门）
    getListTitleFromApprover = (approver) => {
        return approver.label
    }
    changeComponentText = (val, components, sort_id) => {
        let component_text_val_list_obj = {}
        let component_text_val_list = []
        let component_text_val_list_result = []
        let component_key_value_obj_tmp = {}
        let component_text_count = 0
        let components_error = false

        component_text_val_list_obj[sort_id - 1] = val
        component_text_val_list_obj = Object.assign(this.state.component_text_val_list_obj, component_text_val_list_obj)
        // component_text_val_list = Object.values(component_text_val_list_obj)
        component_key_value_obj_tmp[`component_data_${sort_id}`] = val

        for (let i = 0; i < components.length; i++) {
            let component = components[i]
            if (component.component_type < 4 && component.contains_content === 1) { // 1, 2, 3 & 必传
                component_text_count++
            }
        }

        if (components[sort_id - 1].contains_content === 0) {
            delete component_text_val_list_obj[sort_id - 1]
        }
        component_text_val_list = Object.values(component_text_val_list_obj)

        if (component_text_count > 0) {
            if (component_text_count !== component_text_val_list.length) {
                components_error = true
            } else {
                component_text_val_list_result = component_text_val_list.map(component_text_val => {
                    return component_text_val.trim() ? true : false
                })
                const str = component_text_val_list_result.join('')
                components_error = str.indexOf('false') >= 0
            }
        }

        this.setState({
            component_text_val_list_obj,
            component_text_val_list,
            components_error,
            component_key_value_obj: Object.assign(this.state.component_key_value_obj, component_key_value_obj_tmp), // 可改变的组件（针对文案）内容对象
            ...component_key_value_obj_tmp
        }, () => {
            this.getApproverListError()
        })
    }
    changeComponentTextForPackage = (val, type, index) => { // index: 修改第几个包数据
        let package_list = this.state.package_list
        switch (type) {
            case 1: // 包id
                package_list[index].id = val.trim()
                this.setState({
                    package_list
                }, () => {
                    if (this.state.isPackageComponentVisible) {
                        this.setState({
                            package_components_error: this.isPackageListError(),
                        }, () => {
                            this.getApproverListError()
                        })
                    }
                })
                break
            case 2: // 包名
                package_list[index].name = val.trim()
                this.setState({
                    package_list
                }, () => {
                    if (this.state.isPackageComponentVisible) {
                        this.setState({
                            package_components_error: this.isPackageListError()
                        }, () => {
                            this.getApproverListError()
                        })
                    }
                })
                break
            default:
                break
        }

    }
    resetComponents = (components) => {
        const { getFieldProps, getFieldError } = this.props.form
        const { stsToken } = this.props.ossService
        const { ccList, fileList, is_auto_execute, executeType, package_list, platform, feature_name,
            files_contained, deploy_address, sql_string_error_result } = this.state
        return components.map(component => {
            const { component_type, component_name, sort_id, contains_content } = component
            switch (component_type) { // 组件类型：1.主播id组件 2.执行原因textArea组件 3.执行内容组件 4.upload组件 5.抄送组件
                case 1: // 主播ID组件
                    return <List.Item key={sort_id}>
                        <div className={contains_content === 1 ? 'me-list-title necessary' : 'me-list-title'}>{component_name}</div>
                        <List.Item.Brief>
                            <InputItem
                                {...getFieldProps(`component_data_${sort_id}`, {
                                    rules: [{required: contains_content === 1}],
                                    initialValue: this.state.component_key_value_obj[`component_data_${sort_id}`]
                                })}
                                onChange={(val) => this.changeComponentText(val, components, sort_id)}
                                value={this.state.component_key_value_obj[`component_data_${sort_id}`]}
                                placeholder="请输入"
                            />
                        </List.Item.Brief>
                    </List.Item>
                    break
                case 2: // 执行原因组件
                    return <List.Item key={sort_id}>
                        <div className={contains_content === 1 ? 'me-list-title necessary' : 'me-list-title'}>{component_name}</div>
                        <List.Item.Brief>
                            <TextareaItem
                                {...getFieldProps(`component_data_${sort_id}`, {
                                    rules: [{required: contains_content === 1}],
                                    initialValue: this.state.component_key_value_obj[`component_data_${sort_id}`]
                                })}
                                onChange={(val) => this.changeComponentText(val, components, sort_id)}
                                value={this.state.component_key_value_obj[`component_data_${sort_id}`]}
                                rows={3}
                                placeholder="请输入"
                            />
                        </List.Item.Brief>
                    </List.Item>
                    break
                case 3: // 执行内容组件
                    return <List.Item key={sort_id}>
                        <div className={contains_content === 1 ? 'me-list-title necessary' : 'me-list-title'}>{component_name}</div>
                        <List.Item.Brief>
                            <TextareaItem
                                {...getFieldProps(`component_data_${sort_id}`, {
                                    rules: [{required: contains_content === 1}],
                                    initialValue: this.state.component_key_value_obj[`component_data_${sort_id}`]
                                })}
                                onChange={(val) => this.changeComponentText(val, components, sort_id)}
                                value={this.state.component_key_value_obj[`component_data_${sort_id}`]}
                                rows={3}
                                placeholder = {is_auto_execute && executeType === 2 ? '请输入可执行的SQL语句' : '请输入'}
                            />
                            {is_auto_execute && executeType === 2 && sql_string_error_result && <div style={{ color: 'rgba(255, 0, 0, .9)', paddingLeft: 16, marginTop: 6 }}>{sql_string_error_result}</div>}
                        </List.Item.Brief>
                    </List.Item>
                    break
                case 4: // upload组件
                    let { fileList } = this.state
                    fileList = this.getEnableFileList(fileList)
                    const props = {
                        className: 'file-upload-container',
                        name: 'file',
                        listType: 'picture',
                        multiple: true,
                        showUploadList: { // 是否显示文件信息，false这个文件不显示
                            showDownloadIcon: false // 是否显示下载按钮
                        },
                        fileList: fileList,
                        action: `//${stsToken.bucket_name}.${stsToken.endpoint}`,
                        beforeUpload: (file) => this.beforeUpload(file),
                        onChange: this.changeFile,
                        onRemove: this.removeFile,
                        transformFile: this.transformFile,
                        data: this.getExtraData,
                        onPreview: file => this.onPreview(file)
                    }
                    return <List.Item key={sort_id} className="file-container">
                        <div className={contains_content === 1 ? 'me-list-title necessary' : 'me-list-title'}>{component_name}{feature_name === '程序发布' && files_contained ? '（必须提交测试报告）' : ''}</div>
                        <List.Item.Brief>
                            <Upload {...props} className={`upload-container ${fileList && fileList.length > 0 ? 'enable' : ''}`}>
                                <Button className="file-plus"> + </Button>
                            </Upload>
                        </List.Item.Brief>
                    </List.Item>
                    break
                case 5: // 抄送组件
                    return <List.Item key={sort_id} className="cc-container">
                        <div className="me-list-title">{component_name}</div>
                        <List.Item.Brief>
                            <div className="tag-container">
                                {ccList.length > 0 && ccList.map((cc, index) => {
                                    return <Tag
                                        key={cc.role_id}
                                        className="cc-list-item"
                                        closable={cc.is_default === 0}
                                        afterClose={() => this.ccRemove(index)}>
                                        { cc.role_name }
                                    </Tag>
                                })}
                            </div>
                            <Button onClick={this.clickCCPlusBtn} className="cc-plus">+</Button>
                        </List.Item.Brief>
                    </List.Item>
                    break
                case 6: // 包id -> 包名称数组组成的字符串
                    return <List.Item key={6} className="package-container">
                        <div className="me-list-title necessary">{component_name}（按填写顺序执行）</div>
                        <List.Item.Brief>

                            {package_list.map((package_obj, index) => {
                                return <div className='package-item'>
                                    <div className='package-item-inner'>
                                        <div className='left' style={platform === 'pc' ? { display: 'flex' } : {}}>
                                            <InputItem
                                                {...getFieldProps(`component_data_${sort_id}_${index}_1`, {
                                                    rules: [{required: true}],
                                                    initialValue: package_obj.id
                                                })}
                                                onChange={(val) => this.changeComponentTextForPackage(val, 1, index)}
                                                value={package_obj.id}
                                                placeholder="请输入包id"
                                                style={{ padding: '0 6px' }}
                                            >包id</InputItem>
                                            <InputItem
                                                {...getFieldProps(`component_data_${sort_id}_${index}_2`, {
                                                    rules: [{required: true}],
                                                    initialValue: package_obj.name
                                                })}
                                                onChange={(val) => this.changeComponentTextForPackage(val, 2, index)}
                                                value={package_obj.name}
                                                placeholder="请输入程序名"
                                                style={{ padding: '0 6px' }}
                                            >程序名</InputItem>
                                        </div>

                                        {package_list.length >= 2 && <div className='right'>
                                            <Button onClick={() => this.clickPackageMinusBtn(index)} className="package-minus">-</Button>
                                        </div>}
                                    </div>
                                </div>
                            })}
                            <Button onClick={this.clickPackagesPlusBtn} className="cc-plus">+</Button>
                        </List.Item.Brief>
                    </List.Item>
                    break
                case 7: // 包id -> 包名称数组组成的字符串
                    return <List.Item key={7} className="deploy-address-container">
                        <div className="me-list-title necessary">{component_name}</div>
                        <List.Item.Brief>
                            {/*<span className={`deploy-address ${deploy_address ? 'not-empty' : ''}`} onClick={() => this.showDeployAddress()}>{deploy_address ? deploy_address : '请选择'} <Icon type={'down'} /></span>*/}
                            <div className="radio-group" style={{ paddingLeft: 16, marginTop: 6 }}>
                                <div onClick={() => this.changeDeployAddress('公有云')} className={`radio ${deploy_address === '公有云' ? 'checked' : ''}`}>
                                    <div className="circle"><div className="circle-inner"></div></div>
                                    <div className="title">公有云</div>
                                </div>
                                <div onClick={() => this.changeDeployAddress('IDC机房')} className={`radio ${deploy_address === 'IDC机房' ? 'checked' : ''}`}>
                                    <div className="circle"><div className="circle-inner"></div></div>
                                    <div className="title">IDC机房</div>
                                </div>
                            </div>
                        </List.Item.Brief>
                    </List.Item>
                    break
                default:
                    break
            }
        })
    }
    getEnableFileList = (fileList) => {
        for (let i = 0; i < fileList.length; i++) {
            if (fileList[i]['status']) {
                if (fileList[i]['status'] === 'error' || fileList[i]['status'] === 'removed') {
                    fileList.splice(i, 1)
                }
            } else {
                fileList.splice(i, 1)
            }
        }
        return fileList
    }
    beforeUpload(file) {
        const isLt20M = file.size / 1024 / 1024 <= 20
        if (!isLt20M) {
            dd.device.notification.toast({
                text: '文件大小不超过20MB！',
                duration: 2
            })
        }
        return isLt20M
    }
    changeFile = ({ fileList, event }) => {
        this.setState({
            fileList,
            files_error: this.state.files_contained && fileList.length === 0
        })
    }
    removeFile = file => {
        this.setState(state => {
            const index = state.fileList.indexOf(file)
            const newFileList = state.fileList.slice()
            newFileList.splice(index, 1)
            return {
                fileList: newFileList,
                files_error: this.state.files_contained && newFileList.length === 0
            }
        })
        return true
    }
    transformFile = file => {
        const { stsToken } = this.props.ossService
        let fileName = file['name']
        const timestamp = new Date().getTime()
        const fileNameSuffix = getFileNameAndSuffix(fileName)
        fileName = `${fileNameSuffix[0]}_${timestamp}${fileNameSuffix[1]}`
        file.url = `resource/workorder/${getYMD('YYYYMMDD')}/${fileName}`//stsToken.dir + filename;
        file.timestamp = timestamp
        return file
    }
    // upload组件data参数，policy信息
    getExtraData = file => {
        const { stsToken } = this.props.ossService
        const { AccessKeyId, AccessKeySecret, SecurityToken, bucket_name, endpoint } = stsToken

        let timestamp = new Date().getTime()
        timestamp += 172800000 // 2 * 24 * 60 * 60 * 1000
        const newDate = new Date(timestamp)
        newDate.setHours(newDate.getHours(), newDate.getMinutes() - newDate.getTimezoneOffset())
        const utcTime = newDate.toISOString()

        const policyText = {
            expiration: utcTime, // "2020-03-20T12:00:00.000Z", //设置该Policy的失效时间，超过这个失效时间之后，就没有办法通过这个policy上传文件了
            conditions: [
                ["content-length-range", 0, 167772160] // 20MB 设置上传文件的大小限制
            ]
        }
        const policyBase64 = Base64.encode(JSON.stringify(policyText))
        const bytes = Crypto.HMAC(Crypto.SHA1, policyBase64, AccessKeySecret, { asBytes: true })
        const signature = Crypto.util.bytesToBase64(bytes)
        return {
            'key': file.url,
            'OSSAccessKeyId': AccessKeyId,
            'policy': policyBase64,
            'Signature': signature,
            'x-oss-security-token': SecurityToken
        }
    }
    onPreview = (file) => {
        const { token, user_id, fileUrlListObj, fileList } = this.state
        const { getUrlList } = this.props.actions
        const index = fileList.indexOf(file)
        if (fileUrlListObj && fileUrlListObj[index]) { // 存在缓存文件url列表
            this.previewForUpload(file, fileUrlListObj[index])
        } else { // 没有缓存，直接从接口获取文件url列表
            getUrlList({
                token,
                user_id,
                file_list: [file.url],
            }, res => {
                if (res.status === 'success') {
                    const urlList = res.data.file_list
                    const fileUrl = urlList[0]
                    let fileUrlListTmp = {}
                    fileUrlListTmp[index] = fileUrl
                    this.setState({
                        fileUrlListObj: Object.assign(this.state.fileUrlListObj, fileUrlListTmp)
                    }, () => {
                        this.previewForUpload(file, fileUrl)
                    })
                }
            })

        }
    }
    previewForUpload = (file, fileUrl) => { // file: url为相对路径，fileUrl为该file的绝对路径
        const { platform } = this.state
        const fileNameSuffix = getFileNameAndSuffix(file.name)
        let fileSuffix = fileNameSuffix[1]
        fileSuffix = fileSuffix.toLowerCase()
        if (file.type.indexOf('image/') >=0) { // 图片类型文件
            dd.biz.util.previewImage({
                urls: [fileUrl], // 图片地址列表
                current: fileUrl // 当前显示的图片链接
            })
        } else { // 非图片类型文件
            if (platform === 'ios' || platform === 'android') { // ios android
                if (file.type.indexOf('video/') >=0) { // 视频格式
                    let videoTypeSupport = ['.mov', '.mp4', '.3gp', '.mpv'] // MOV、MP4、3GP、MPV
                    videoTypeSupport = videoTypeSupport.join()
                    if (videoTypeSupport.indexOf(fileSuffix) >= 0) {
                        if (platform === 'ios') { // ios
                            this.setState({
                                previewVideo: fileUrl,
                                previewVideoVisible: true
                            })
                        } else { // android
                            dd.device.notification.toast({
                                text: '请在PC端查看！',
                                duration: 2
                            })
                        }
                    } else {
                        dd.device.notification.toast({
                            text: '不支持该视频格式，请在PC端查看！',
                            duration: 2
                        })
                    }
                } else { // 非视频格式（.xls .xlsx .doc .docx .sql .txt...）
                    let textTypeSupport = ['.xls', '.xlsx', '.doc', '.docx', '.txt', '.pdf']
                    textTypeSupport = textTypeSupport.join()
                    if (textTypeSupport.indexOf(fileSuffix) >= 0) {
                        downloadFile(file)
                    } else {
                        dd.device.notification.toast({
                            text: '不支持该文件格式的预览！',
                            duration: 2
                        })
                    }
                }
            } else { // pc
                downloadFile(file)
            }
        }
    }
    closePreview = () => {
        const { previewVisible, previewVideoVisible } = this.state
        if (previewVisible) {
            this.setState({ previewVisible: false })
        }
        if (previewVideoVisible) {
            this.setState({ previewVideoVisible: false })
        }
    }
    // 审批流程处理人、部门交互
    getHandleName = (approver) => {
        let { approversForCreate, platform } = this.state
        const { node_id, sort_id, handle_userid, handle_name, handle_depname, new_handle_depid, new_handle_depname, userdata } = approver
        if (node_id === 5) { // 执行节点
            return <Steps.Step
                className="approve-list-steps"
                key={sort_id}
                icon={sort_id}
                title={<span>
                    <span className="step-title">执行部门：</span>
                    <span className="step-content">{handle_depname}</span>
                </span>}
            />
        } else { // 审批节点
            if (handle_name) { // 默认指定审批人
                return <Steps.Step
                    className="approve-list-steps"
                    key={sort_id}
                    icon={sort_id}
                    title={<span>
                        <span className="step-title">部门：</span>
                        <span className="step-content">{handle_depname}</span>
                    </span>}
                    description={<span>
                        <span className="step-title">审核人：</span>
                        <span className="step-content">{handle_name}</span>
                    </span>}
                />
            } else { // 无指定审批人
                if (node_id === 1) { // 本部门审批（可能存在一个审批人或多个审批人，且审批人为部门领导）
                    if (userdata.length === 1) { // 领导只有一个部门
                        approversForCreate[sort_id - 1]['new_handle_name'] = userdata[0]['handle_name']
                        approversForCreate[sort_id - 1]['new_handle_userid'] = userdata[0]['handle_userid']
                        approversForCreate[sort_id - 1]['new_handle_depid'] = userdata[0]['handle_depid']
                        approversForCreate[sort_id - 1]['new_handle_depname'] = userdata[0]['handle_depname']
                        return <Steps.Step
                            className="approve-list-steps"
                            key={sort_id}
                            icon={sort_id}
                            title={<span>
                                <span className="step-title">部门：</span>
                                <span className="step-content">{userdata[0]['handle_depname']}</span>
                            </span>}
                            description={<span>
                                <span className="step-title">审核人：</span>
                                <span className="step-content">{userdata[0]['handle_name']}</span>
                            </span>}
                        />
                    } else { // 领导存在多部门情况
                        const approver = approversForCreate[sort_id - 1]
                        const { new_handle_name } = approver
                        if (new_handle_depid) { // 部门已选择
                            let dep_id_name = {}
                            const chooseData = userdata.map(user => {
                                dep_id_name[user.handle_depid] = {
                                    new_handle_userid: user.handle_userid,
                                    new_handle_name: user.handle_name,
                                    new_handle_depid: user.handle_depid,
                                    new_handle_depname: user.handle_depname,
                                }
                                return { label: user.handle_depname, value: user.handle_depid}
                            })
                            return <Steps.Step
                                className="approve-list-steps"
                                key={sort_id}
                                icon={sort_id}
                                title={platform === 'pc' ?
                                    (<List.Item className="step-title">审核部门：
                                        {new_handle_depname ? <span onClick={() => this.showApproversModal(0, chooseData, sort_id, dep_id_name, '审核部门')} className="step-content pointer">{new_handle_depname} <Icon type={'down'} /></span> : <span onClick={() => this.showApproversModal(0, chooseData, sort_id, dep_id_name, '审核部门')} className="step-content placeholder pointer">请选择 <Icon type={'down'} /></span>}
                                    </List.Item>): (<Picker
                                        cols={1}
                                        data={chooseData}
                                        onOk={value => this.chooseApproverForDeps(value, sort_id, dep_id_name)}
                                        extra={new_handle_depname ? <span className="step-content">{new_handle_depname} <Icon type={'down'} /></span> : <span className="step-content placeholder">请选择 <Icon type={'down'} /></span>}
                                    >
                                        <List.Item className="step-title">审核部门：</List.Item>
                                    </Picker>)
                                }
                                description={<span>
                                    <span className="step-title">审核人：</span>
                                    <span className="step-content">{new_handle_name}</span>
                                </span>}
                            />
                        } else { // 部门未选择
                            let dep_id_name = {}
                            const chooseData = userdata.map(user => {
                                dep_id_name[user.handle_depid] = {
                                    new_handle_userid: user.handle_userid,
                                    new_handle_name: user.handle_name,
                                    new_handle_depid: user.handle_depid,
                                    new_handle_depname: user.handle_depname,
                                }
                                return { label: user.handle_depname, value: user.handle_depid}
                            })
                            return <Steps.Step
                                className="approve-list-steps"
                                key={sort_id}
                                icon={sort_id}
                                title={platform === 'pc' ?
                                    (<List.Item className="step-title">审核部门：
                                        {new_handle_name ? <span onClick={() => this.showApproversModal(1, chooseData, sort_id, dep_id_name, '审核部门')} className="step-content pointer">{new_handle_name} <Icon type={'down'} /></span> : <span onClick={() => this.showApproversModal(1, chooseData, sort_id, dep_id_name, '审核部门')} className="step-content placeholder pointer">请选择 <Icon type={'down'} /></span>}
                                    </List.Item>) : (<Picker
                                        cols={1}
                                        data={chooseData}
                                        placeholder="请输入"
                                        onOk={value => this.chooseApproverForDeps(value, sort_id, dep_id_name)}
                                        extra={new_handle_name ? <span className="step-content">{new_handle_name} <Icon type={'down'} /></span> : <span className="step-content placeholder">请选择 <Icon type={'down'} /></span>}
                                    >
                                        <List.Item className="step-title">审核部门：</List.Item>
                                    </Picker>)
                                }
                            />
                        }
                    }
                } else { // 其他部门审批（此处肯定存在多个审批人）
                    const approver = approversForCreate[sort_id - 1]
                    const { new_handle_name } = approver
                    let user_id_name = {} // 创建一个用户ID：Key的对象，形如：{'id_1': 'name_1', 'id_2': 'name_2'...}
                    const chooseData = userdata.map(user => {
                        user_id_name[user.handle_userid] = user.handle_name
                        return { label: user.handle_name, value: user.handle_userid}
                    })
                    return <Steps.Step
                        className="approve-list-steps"
                        key={sort_id}
                        icon={sort_id}
                        title={<span>
                            <span className="step-title">部门：</span>
                            <span className="step-content">{handle_depname}</span>
                        </span>}
                        description={platform === 'pc' ?
                            (<List.Item>审核人：
                                {new_handle_name ? <span onClick={() => this.showApproversModal(2, chooseData, sort_id, user_id_name, '审核人')} className="step-content pointer">{new_handle_name} <Icon type={'down'} /></span> : <span onClick={() => this.showApproversModal(2, chooseData, sort_id, user_id_name, '审核人')} className="step-content placeholder pointer">请选择 <Icon type={'down'} /></span>}
                            </List.Item>) : (<Picker
                                cols={1}
                                data={chooseData}
                                onOk={value => this.chooseApproverForOneDep(value, sort_id, user_id_name)}
                                extra={new_handle_name ? <span className="step-content">{new_handle_name} <Icon type={'down'} /></span> : <span className="step-content placeholder">请选择 <Icon type={'down'} /></span>}
                            >
                                <List.Item className="step-title">审核人：</List.Item>
                            </Picker>)
                        }
                    />
                }
            }
        }
    }
    showApproversModal= (type, chooseData, sort_id, id_name, title) => {
        this.setState({
            chooseData: chooseData,
            approversModalVisible: true,
            approversModalTitle: title,
            approversModalType: type,
            approvers_node_sort_id: sort_id,
            approvers_node_user_id_name: id_name,
        })
    }
    closeApproversModal = () => {
        this.setState({ approversModalVisible: false })
    }
    // 本部门选择多个部门（领导是多部门）
    chooseApproverForDeps = (value, sort_id, dep_id_name) => {
        let { approversForCreate, platform } = this.state
        if (approversForCreate) {
            approversForCreate[sort_id - 1]['new_handle_depid'] = dep_id_name[value[0]]['new_handle_depid']
            approversForCreate[sort_id - 1]['new_handle_depname'] = dep_id_name[value[0]]['new_handle_depname']
            approversForCreate[sort_id - 1]['new_handle_name'] = dep_id_name[value[0]]['new_handle_name']
            approversForCreate[sort_id - 1]['new_handle_userid'] = dep_id_name[value[0]]['new_handle_userid']
            this.setState({ approversForCreate: approversForCreate }, () => {
                this.getApproverListError()
                if (platform === 'pc') {
                    this.closeApproversModal() // 选择部门之后，关闭弹框
                }
            })
        }
    }
    // 其他部门审批人选择，handle_depid已经存在
    chooseApproverForOneDep = (value, sort_id, user_id_name) => {
        let { approversForCreate, platform } = this.state
        if (approversForCreate) {
            approversForCreate[sort_id - 1]['new_handle_name'] = user_id_name[value[0]]
            approversForCreate[sort_id - 1]['new_handle_userid'] = value[0]
            this.setState({ approversForCreate: approversForCreate }, () => {
                this.getApproverListError()
                if (platform === 'pc') {
                    this.closeApproversModal() // 选择审批人之后，关闭弹框
                }
            })
        }
    }
    ccRemove = (index) => {
        const { ccList } = this.state
        const ccRemoved = ccList[index] // 删除项
        const { is_default, rule_role_type, role_id } = ccRemoved // is_default：是否默认抄送对象（1是 0否），rule_role_type：1自定义组 2单人 3部门
        ccList.splice(index, 1)
        if (is_default === 1) { // 删除默认抄送对象
            this.setState({
                pickedCCDefaultList: this.getListAfterRemove(this.state.pickedCCDefaultList, role_id),
            })
        } else { // 删除非默认抄送对象
            switch (rule_role_type) {
                case 1:
                    this.setState({
                        pickedCombinedDepartments: this.getListAfterRemove(this.state.pickedCombinedDepartments, role_id),
                    })
                    break
                case 2:
                    this.setState({
                        pickedUsers: this.getListAfterRemove(this.state.pickedUsers, role_id),
                    })
                    break
                case 3:
                    this.setState({
                        pickedDepartments: this.getListAfterRemove(this.state.pickedDepartments, role_id),
                    })
                    break
                default:
                    break
            }
        }
        this.setState({
            ccList: ccList,
        })
    }
    // 默认抄送中的人（自定义组，暂时不包括），不可选择
    getDisabledUserIdList = () => {
        const { ccDefaultList } = this.state
        let disabledUserIdList = []
        let disabledDepartmentIdList = []
        for (let i = 0; i < ccDefaultList.length ; i++) {
            switch (ccDefaultList[i]['rule_role_type']) {
                case 1: // 自定义组
                    disabledDepartmentIdList.push(ccDefaultList[i]['role_id'])
                    break
                case 2: // 个人
                    disabledUserIdList.push(ccDefaultList[i]['role_id'])
                    break
                default:
                    break
            }
        }
        return {
            disabledUserIdList,
            disabledDepartmentIdList
        }
    }
    getListAfterRemove = (list, role_id_removed) => {
        let newList = []
        for (let i = 0; i < list.length; i++) {
            if (role_id_removed !== list[i]) {
                newList.push(list[i])
            }
        }
        return newList
    }
    resetComponentsData = (components, component_values) => {
        return components.map((component) => {
            let component_data = ''
            switch (component.component_type) {
                case 1: case 2: case 3:
                    component_data = this.state[`component_data_${component.sort_id}`] || ''
                    break
                case 4: // upload组件
                    component_data = []
                    break
                case 5: // 抄送组件
                    const { ccList } = this.state
                    let nameStr = ''
                    for (let i = 0; i < ccList.length; i++) {
                        nameStr += `${ccList[i]['role_name']}，`
                    }
                    nameStr = nameStr.slice(0, nameStr.length - 1)
                    component_data = nameStr
                    break
                case 6:
                    component_data = this.state.package_list
                    break
                case 7:
                    component_data = this.state.deploy_address
                    break
                default:
                    break

            }
            return Object.assign(component, { component_data: component_data })
        })
    }
    // 获取创建工单的基础信息（包括创建人和组件数据）
    getBaseOrderInfo = (page_components) => {
        const { appid, operation_id, feature_id, user_id, user_department_id } = this.state
        return {
            appid: appid,
            operation_type: operation_id,
            feature_type: feature_id,
            user_id: user_id, //
            apply_depid: user_department_id.toString(),
            page_components
        }
    }
    // 重新组合审批流程数据
    getApproverList = () => {
        const { approversForCreate } = this.state
        const approverList = approversForCreate.map(approver => {
            let { node_id, sort_id, node_count, handle_depid, new_handle_depid, handle_userid, new_handle_userid, handle_name, new_handle_name } = approver
            handle_depid = new_handle_depid ? new_handle_depid : handle_depid
            handle_userid = new_handle_userid ? new_handle_userid : handle_userid,
            handle_name = new_handle_name ? new_handle_name : handle_name
            return { node_id, sort_id, node_count, handle_depid, handle_userid, handle_name }
        })
        return approverList
    }
    // 点击增加抄送人按钮
    clickCCPlusBtn = () => {
        const _this = this
        const { jsApiSignature } = this.props.dingtalkService
        let { ccDefaultList, wfid, pickedDepartments, pickedUsers } = this.state
        const { disabledUserIdList } = this.getDisabledUserIdList(ccDefaultList)
        let ccAllList = []
        this.showIndicatorLoading()
        setDDConfig(jsApiSignature, window.location.href, () => {
            dd.biz.contact.complexPicker({
                title: "抄送",                                   // 标题
                corpId: getCorpId(),                            // 企业的corpId
                multiple: true,                                 // 是否多选
                limitTips: "超出了人数限制",                       // 超过限定人数返回提示
                maxUsers: 1000,                                 // 最大可选人数
                pickedUsers,                                    // 已选用户
                pickedDepartments,                              // 已选部门
                requiredUsers: [],                              // 必选用户（不可取消选中状态）
                requiredDepartments: [],                        // 必选部门（不可取消选中状态）
                disabledUsers: disabledUserIdList,              // 不可选用户
                appId: getAppId(),                              // 微应用的Id
                permissionType: "GLOBAL",                       // 选人权限，目前只有GLOBAL这个参数
                responseUserOnly: true,                         // true：返回人员信息，false：返回人员和部门信息
                startWithDepartmentId: 0,                       // 0表示从企业最上层开始，-1 表示从自己所在部门开始
                onSuccess: function (result) {
                    /**
                     * departments（id:142530690 name:"业务运维部" number:3）
                     * users（avatar:"" emplId:"290335095623401189" name:"宋汉元"）
                     * selectedCount:5
                     */
                    _this.hideIndicatorLoading()
                    let {departments, users} = result
                    ccDefaultList = ccDefaultList.map((cc, index) => {
                        return {
                            ...cc,
                            is_default: 1,
                        }
                    })
                    departments = departments.map((department, index) => {
                        return {
                            wfid: wfid,
                            // rule_id: 2, // ???????
                            rule_role_type: 3, // 1自定义组 2单人 3部门
                            role_id: department.id.toString(),
                            is_default: 0,
                            role_name: department.name,
                        }
                    })
                    users = users.map((user, index) => {
                        return {
                            wfid: wfid,
                            // rule_id: 1,
                            rule_role_type: 2,
                            role_id: user.emplId,
                            is_default: 0,
                            role_name: user.name,
                        }
                    })
                    ccAllList = ccAllList.concat(ccDefaultList).concat(departments).concat(users)
                    _this.setState({
                        // pickedCCDefaultList: _this.getPickedIdList(ccDefaultList),
                        pickedDepartments: _this.getPickedIdList(departments),
                        pickedUsers: _this.getPickedIdList(users),
                        ccList: ccAllList,
                    })
                },
                onFail: function (err) {
                    _this.hideIndicatorLoading()
                    console.log(err)
                }
            })
        })
    }
    // 点击增加包信息发布内容
    clickPackagesPlusBtn = () => {
        let package_list = this.state.package_list
        let package_obj = {
            id: null,
            name: null
        }
        package_list.push(package_obj)
        this.setState({
            package_list: package_list
        }, () => {
            this.setState({
                package_components_error: this.isPackageListError()
            })
        })
    }
    // 显示选择发布程序地址弹框
    showDeployAddress = () => {
        const _this = this
        const addressList = ['公有云', 'IDC机房']
        dd.device.notification.actionSheet({
            title: `选择发布地址`,
            cancelButton: '取消',
            otherButtons: addressList,
            onSuccess : function(result) {
                const { buttonIndex } = result
                if (buttonIndex >= 0) {
                    const deploy_address = addressList[buttonIndex]
                    _this.setState({
                        deploy_address,
                    })
                }
            },
            onFail : function(err) {console.log(err)}
        })
    }

    // 删除package数据项
    clickPackageMinusBtn = (index) => {
        let package_list = this.state.package_list
        package_list.splice(index, 1)
        this.setState({
            package_list
        }, () => {
            this.setState({
                package_components_error: this.isPackageListError()
            })
        })
    }
    // 判断审批人列表是否填写完整
    getApproverListError = (callback) => {
        const in_approval_list = this.getApproverList()
        let errorCount = 0
        for (let i = 0; i < in_approval_list.length; i++) {
            const {node_id, handle_userid, handle_depid} = in_approval_list[i]
            if (node_id !== 5) {
                if (!handle_userid || !handle_depid) {
                    errorCount++
                }
            }
        }
        if (errorCount > 0) {
            this.setState({
                approvers_error: true
            }, () => {
                callback && callback(true)
            })
        } else {
            this.setState({
                approvers_error: false
            }, () => {
                callback && callback(false)
            })
        }
    }
    // 提交工单之前，检测fileList中是否存在文件上传未成功的情况
    checkFileList = (fileList) => {
        for (let i = 0; i < fileList.length; i++) {
            if (fileList[i]['status'] !== 'done') {
                dd.device.notification.toast({
                    text: '有文件上传未成功，请等待一会后继续提交或者尝试重新刷新页面后继续操作！',
                    duration: 2
                })
                return
            }
        }
    }
    // 将原附件文件转为上传所需的资源格式（资源类型：1.excel(.xls .xlsx) 2.sql文件(.sql .mdf ...) 3.图片 4.压缩包rar' 5视频类型（iphone支持MOV、MP4、3GP、MPV四种格式））
    getResource = (fileList) => {
        const resource = fileList.map(file => {
            let resource_info = {
                resource_url: file.url,
                resource_name: file.name,
                uid: file.uid,
            }
            if (file.type.indexOf('image/') >=0) {
                resource_info.type = 3
            } else if (file.type.indexOf('video/') >=0) {
                resource_info.type = 5
            }
            return resource_info
        })
        return resource
    }
    submit = () => {
        this.props.form.validateFields((err, values) => { // 这里的values有问题。需要使用state中的值
            if (!err) {
                const { createOrder, syntaxCheck } = this.props.actions
                const { ccList, token, user_id, components_error, approvers_error, database_error, is_auto_execute,
                    fileList, database, executeType } = this.state
                let { components } = this.props.workOrderService
                const page_components = this.resetComponentsData(components, values)
                const in_approval_list = this.getApproverList()
                if (components_error) {
                    dd.device.notification.toast({
                        text: '请确认审批内容是否完整！',
                        duration: 2
                    })
                }
                if (approvers_error) {
                    dd.ready(() => {
                        dd.device.notification.toast({
                            text: '请确认审批流程是否完整！',
                            duration: 2
                        })
                    })
                    return
                }
                if (is_auto_execute && database_error) {
                    dd.device.notification.toast({
                        text: '请确认执行方式是否选择！',
                        duration: 2
                    })
                    return
                }
                this.checkFileList(fileList) // 如果检测文件上传未成功，则暂停后续操作
                const in_apply_history = this.getBaseOrderInfo(page_components)
                let orderParams = {
                    in_apply_history: in_apply_history,
                    in_resource: this.getResource(fileList),
                    in_cc_list: ccList,
                    in_approval_list: in_approval_list
                }
                if (is_auto_execute && database) {
                    orderParams.db_name = database
                }
                if (is_auto_execute && executeType === 2) { // 自动执行sql
                    const sql_string = this.getSqlString(components)
                    if (sql_string) { // sql语句存在
                        syntaxCheck({
                            token,
                            user_id,
                            sql_string: sql_string,
                            app_name: this.state.app_name_code,
                            db_name: database
                        }, res => {
                            if (res.status === 'success') {
                                this.setState({
                                    sql_string_error_result: ''
                                })
                                this.showIndicatorLoading()
                                createOrder({
                                    token,
                                    user_id,
                                    ...orderParams
                                }, res => {
                                    this.hideIndicatorLoading()
                                    if (res.status === 'success') {
                                        history.push(`/app/detail?apply_id=${res.data.apply_post.apply_id}`)
                                    }
                                })
                            } else {
                                this.setState({
                                    sql_string_error_result: res.detail
                                })
                            }
                        })
                    }
                } else {
                    this.showIndicatorLoading()
                    createOrder({
                        token,
                        user_id,
                        ...orderParams
                    }, res => {
                        this.hideIndicatorLoading()
                        if (res.status === 'success') {
                            history.push(`/app/detail?apply_id=${res.data.apply_post.apply_id}`)
                        }
                    })
                }
                // dd.device.notification.alert({
                //     message: JSON.stringify(orderParams),
                //     title: "提示",//可传空
                //     buttonName: "收到",
                //     onSuccess : function() {
                //         //onSuccess将在点击button之后回调
                //         /*回调*/
                //     },
                //     onFail : function(err) {}
                // });
            }
        })
    }
    getSqlString = (components) => {
        for (let i = 0; i < components.length; i++) {
            if (components[i].component_type === 3) {
                return components[i].component_data
            }
        }
        return null
    }
    // 选择执行方式
    changeExecuteType = (type) => {
        if (type !== this.state.executeType) {
            this.setState({
                executeType: type,
                database_error: type === 2,
                database: '',
            })
        }
    }
    changeDeployAddress = (deploy_address) => {
        if (deploy_address !== this.state.deploy_address) {
            this.setState({
                deploy_address,
            })
        }
    }
    // 获取已经选择的用户/部门id列表
    getPickedIdList = (list) => {
        return list.map(item => {
            return item.role_id
        })
    }
    showDataBaseModal = () => {
        this.setState({
            databaseModalVisible: true
        })
    }
    closeDataBaseModal = () => {
        this.setState({
            databaseModalVisible: false
        })
    }
    // 选择数据库
    chooseDataBase = (db) => {
        // let database_error = this.state.database_error
        // let { executeType } = this.state
        // if (executeType === 2) {
        //     database_error = false
        // }
        if (typeof db === 'object' && db.length === 1) {
            db = db.toString()
        }
        this.setState({
            database: db,
            databaseModalVisible: false,
            database_error: false,
        })
    }
    showIndicatorLoading = () => {
        this.setState({ activityIndicatorAnimating: true }, () => {
            setTimeout(() => {
                if (this.state.activityIndicatorAnimating) {
                    this.setState({activityIndicatorAnimating: false})
                }
            }, 6000)
        })
    }
    hideIndicatorLoading = () => {
        this.setState({activityIndicatorAnimating: false})
    }
}

const mapStateToProps = (state) => ({
    accountService: state.accountService,
    workOrderService: state.workOrderService,
    dingtalkService: state.dingtalkService,
    ossService: state.ossService,
    dbService: state.dbService,
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Object.assign({}, accountServiceActions, workOrderServiceActions, dingtalkServiceActions, ossServiceActions, dbServiceActions), dispatch)
})
const SubmitContentForm = createForm()(SubmitContent);
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubmitContentForm)
