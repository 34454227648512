import { put, call, takeEvery } from 'redux-saga/effects'
import * as actionTypes from '@/store/actionTypes/workOrderService'
import * as actions from '@/store/actions/workOrderService'
import * as request from './request'

export const workOrderServiceSaga = [
    takeEvery(actionTypes.GET_CASCADING_MENU, getCascadingMenu), // 获取 app_id、operation、feature
    takeEvery(actionTypes.GET_COMPONENTS, getComponents), // 获取页面组件内容
    takeEvery(actionTypes.GET_CC_LIST_FOR_CREATE, getCCListForCreate), // 获取审批人列表（新建工单）
    takeEvery(actionTypes.GET_APPROVERS_FOR_CREATE, getApproversForCreate), // 获取审批人列表（新建工单）
    takeEvery(actionTypes.GET_COMPONENTS_FOR_DETAIL, getComponentsForDetail), // 获取工单详情组件内容（工单详情）
    takeEvery(actionTypes.CREATE_ORDER, createOrder), // 新建工单
    takeEvery(actionTypes.GET_APPROVERS_FOR_DETAIL, getApproversForDetail), // 获取审批人列表（工单详情）
    takeEvery(actionTypes.GET_BUTTON_LIST, getButtonList), // 获取可操作按钮（工单详情）
    takeEvery(actionTypes.ORDER_URGE, orderUrge), // 催办
    takeEvery(actionTypes.ORDER_CANCEL, orderCancel), // 撤销
    takeEvery(actionTypes.ORDER_APPROVE_DONE, orderApproveDone), // 审批通过
    takeEvery(actionTypes.ORDER_APPROVE_REJECT, orderApproveReject), // 审批驳回
    takeEvery(actionTypes.ORDER_EXECUTE, orderExecute), // 执行
    takeEvery(actionTypes.ORDER_EXECUTE_REJECT, orderExecuteReject), // 执行驳回
    takeEvery(actionTypes.ORDER_EXECUTE_DONE, orderExecuteDone), // 执行完成
    takeEvery(actionTypes.ORDER_AUTO_EXECUTE, orderAutoExecute), // 自动执行
    takeEvery(actionTypes.CHECK_WFID_ENABLE, checkWfidEnable), // 检测wfid是否有效
    takeEvery(actionTypes.CHECK_APPLY_ID_AUTHORIZED, checkApplyIdAuthorized), // 检测apply_id是否有权限
    takeEvery(actionTypes.GET_NEXT_TASK_APPLY_ID, getNextTaskApplyId), // 获取下一条待操作的工单
    takeEvery(actionTypes.SYNTAX_CHECK, syntaxCheck), // 检测自动执行语句是否有效
]

export function* syntaxCheck(action) {
    try {
        const data = yield call(request.syntaxCheck, action)
        action.callback && action.callback(data)
        yield put(actions.syntaxCheckSuccess(data))
    } catch(error) {
        yield put(actions.syntaxCheckFailed())
    }
}

export function* getNextTaskApplyId(action) {
    try {
        const data = yield call(request.getNextTaskApplyId, action)
        action.callback && action.callback(data)
        yield put(actions.getNextTaskApplyIdSuccess(data))
    } catch(error) {
        yield put(actions.getNextTaskApplyIdFailed())
    }
}

export function* checkApplyIdAuthorized(action) {
    try {
        const data = yield call(request.checkApplyIdAuthorized, action)
        action.callback && action.callback(data)
        yield put(actions.checkApplyIdAuthorizedSuccess(data))
    } catch(error) {
        yield put(actions.checkApplyIdAuthorizedFailed())
    }
}

export function* checkWfidEnable(action) {
    try {
        const data = yield call(request.checkWfidEnable, action)
        action.callback && action.callback(data)
        yield put(actions.checkWfidEnableSuccess(data))
    } catch(error) {
        yield put(actions.checkWfidEnableFailed())
    }
}

export function* orderAutoExecute(action) {
    try {
        const data = yield call(request.orderAutoExecute, action)
        action.callback && action.callback(data)
        yield put(actions.orderAutoExecuteSuccess(data))
    } catch(error) {
        yield put(actions.orderAutoExecuteFailed())
    }
}

export function* orderExecuteDone(action) {
    try {
        const data = yield call(request.orderExecuteDone, action)
        action.callback && action.callback(data)
        yield put(actions.orderExecuteDoneSuccess(data))
    } catch(error) {
        yield put(actions.orderExecuteDoneFailed())
    }
}

export function* orderExecuteReject(action) {
    try {
        const data = yield call(request.orderExecuteReject, action)
        action.callback && action.callback(data)
        yield put(actions.orderExecuteRejectSuccess(data))
    } catch(error) {
        yield put(actions.orderExecuteRejectFailed())
    }
}

export function* orderExecute(action) {
    try {
        const data = yield call(request.orderExecute, action)
        action.callback && action.callback(data)
        yield put(actions.orderExecuteSuccess(data))
    } catch(error) {
        yield put(actions.orderExecuteFailed())
    }
}

export function* orderApproveReject(action) {
    try {
        const data = yield call(request.orderApproveReject, action)
        action.callback && action.callback(data)
        yield put(actions.orderApproveRejectSuccess(data))
    } catch(error) {
        yield put(actions.orderApproveRejectFailed())
    }
}

export function* orderApproveDone(action) {
    try {
        const data = yield call(request.orderApproveDone, action)
        action.callback && action.callback(data)
        yield put(actions.orderApproveDoneSuccess(data))
    } catch(error) {
        yield put(actions.orderApproveDoneFailed())
    }
}

export function* orderCancel(action) {
    try {
        const data = yield call(request.orderCancel, action)
        action.callback && action.callback(data)
        yield put(actions.orderCancelSuccess(data))
    } catch(error) {
        yield put(actions.orderCancelFailed())
    }
}

export function* orderUrge(action) {
    try {
        const data = yield call(request.orderUrge, action)
        action.callback && action.callback(data)
        yield put(actions.orderUrgeSuccess(data))
    } catch(error) {
        yield put(actions.orderUrgeFailed())
    }
}





export function* getButtonList(action) {
    try {
        const data = yield call(request.getButtonList, action)
        action.callback && action.callback(data)
        yield put(actions.getButtonListSuccess(data))
    } catch(error) {
        yield put(actions.getButtonListFailed())
    }
}

export function* getApproversForDetail(action) {
    try {
        const data = yield call(request.getApproversForDetail, action)
        action.callback && action.callback(data)
        yield put(actions.getApproversForDetailSuccess(data))
    } catch(error) {
        yield put(actions.getApproversForDetailFailed())
    }
}

export function* createOrder(action) {
    try {
        const data = yield call(request.createOrder, action)
        action.callback && action.callback(data)
        yield put(actions.createOrderSuccess(data))
    } catch(error) {
        yield put(actions.createOrderFailed())
    }
}

export function* getComponentsForDetail(action) {
    try {
        const data = yield call(request.getComponentsForDetail, action)
        action.callback && action.callback(data)
        yield put(actions.getComponentsForDetailSuccess(data))
    } catch(error) {
        yield put(actions.getComponentsForDetailFailed())
    }
}

export function* getApproversForCreate(action) {
    try {
        const data = yield call(request.getApproversForCreate, action)
        action.callback && action.callback(data)
        yield put(actions.getApproversForCreateSuccess(data))
    } catch(error) {
        yield put(actions.getApproversForCreateFailed())
    }
}

export function* getCCListForCreate(action) {
    try {
        const data = yield call(request.getCCListForCreate, action)
        action.callback && action.callback(data)
        yield put(actions.getCCListForCreateSuccess(data))
    } catch(error) {
        yield put(actions.getCCListForCreateFailed())
    }
}

export function* getComponents(action) {
    try {
        const data = yield call(request.getComponents, action)
        action.callback && action.callback(data)
        yield put(actions.getComponentsSuccess(data))
    } catch(error) {
        yield put(actions.getComponentsFailed())
    }
}

export function* getCascadingMenu(action) {
    try {
        const data = yield call(request.getCascadingMenu, action)
        action.callback && action.callback(data)
        yield put(actions.getCascadingMenuSuccess(data))
    } catch(error) {
        // action.callback && action.callback({
        //     status: 'error',
        //     data: {}
        // })
        yield put(actions.getCascadingMenuFailed())
    }
}
