import Index from '@/views/index'
import SubmitActions from '@/views/component/submit/actions'
import SubmitContent from '@/views/component/submit/content'
import Detail from '@/views/component/detail'

export const routes = [
    {
        path: '/app', component: Index, routes: [
            { path: '/app/actions', name: '发起工单', title: '发起工单', component: SubmitActions },
            { path: '/app/content', name: '发起工单', title: '发起工单', component: SubmitContent },
            { path: '/app/detail', name: '工单详情', title: '工单详情', component: Detail },
        ]
    },
    { path: '/', name: '发起工单', redirect: '/app/actions', exact: true },
    // { path: '/', name: '发起工单', title: '发起工单', component: SubmitActions, exact: true },
    // { path: '/content', name: '发起工单', title: '发起工单', component: SubmitContent },
    // { path: '/detail', name: '工单详情', title: '工单详情', component: Detail },
]
//
// export const routesConfig = {
//     routes
// }