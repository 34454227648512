import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import MeComponent from '@/views/component'
import { getAppKey } from '@/util/config'
import { setStorage } from '@/util/storage'
import { requestAuthCode } from '@/util/ding'
import * as accountServiceActions from '@/store/actions/accountService'

class Index extends React.Component {
    componentDidMount() {
        const { login } = this.props.actions
        const app_key = getAppKey()
        requestAuthCode(code => {
            login({ code, app_key }, res => {
                if (res.status === 'success') {
                    this.saveUserInfo(res.data)
                }
            })
        })
    }
    render () {

        return (
            <MeComponent routes={this.props.routes}></MeComponent>
        )
    }
    saveUserInfo = (user) => {
        const { token, user_id, department, avatar, name } = user
        this.saveToken(token)
        this.saveUserId(user_id)
        this.saveUserName(name)
        if (department.length > 0) {
            setStorage('user_department', JSON.stringify(department))
        }
        if (avatar) {
            setStorage('user_avatar', avatar)
        }
    }
    saveToken (token) {
        if (token) {
            setStorage('user_token', token)
        }
    }
    saveUserId (user_id) {
        if (user_id) {
            setStorage('user_id', user_id)
        }
    }
    saveUserName (name) {
        if (name) {
            setStorage('user_name', name)
        }
    }
    getUserInfo (token, user_id) {
        const { getUserInfo } = this.props.actions
        getUserInfo({token, user_id})
    }
    getStsToken (token, user_id) {
        const { getStsToken } = this.props.actions
        getStsToken({token, user_id})
    }
}

const mapStateToProps = (state) => ({
    accountService: state.accountService,
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Object.assign({}, accountServiceActions), dispatch)
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Index)
